import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#389d74",
    },
    secondary: {
      main: "rgb(46,46,46)",
    },
  },
});
