import { CircularProgress, ThemeProvider } from "@mui/material";
import React from "react";
import { theme } from "../../utils/theme";
type LoaderProps = {
  size?: number;
};
const Loader = ({ size }: LoaderProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress size={size ?? 150} color="primary" />
    </ThemeProvider>
  );
};

export default Loader;
