import { Certificates } from "../types/TypeSpecialist";

export const formatedFiles = (filesArr: Omit<Certificates, "_id">[]) => {
  return filesArr.map((imageObject) => {
    const uint8Array = new Uint8Array(imageObject.buffer.data);
    const blob = new Blob([uint8Array], { type: "image/png" });
    const dataUrl = URL.createObjectURL(blob);
    return dataUrl;
  });
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)) as any);

  return Math.round((bytes / Math.pow(k, i)) * 100) / 100 + " " + sizes[i];
};
export const downloadFile = (
  url: string,
  fileName: string,
  fileType: string
) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + "." + fileType);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch((error) => console.error("Error downloading file:", error));
};
