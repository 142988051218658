import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";

import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";
import {
  Certificates,
  OverLaps,
  ParamsAvailable,
  SpecialistData,
  TypeSpecialistFilter,
} from "../types/TypeSpecialist";
import { Sessions } from "../types/TypeSession";

const { specialists } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "specialists/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SpecialistApi {
  static async getFilteredSpecialists(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
      overlapDays: OverLaps[];
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.getFilteredSpecialists}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAvailableParams(
    token: string,
    payload: { param: string; filters: TypeSpecialistFilter }
  ): Promise<string[]> {
    return await ky
      .post(`${baseURL}getAvailableParams`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async update(
    updateData: TypeUpdatedUserInfo,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      specialistData: SpecialistData;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async getSpecialistCertificates(
    token: string,
    id: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: Certificates[];
      total: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getSpecialistCertificates}/${id}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }
}
