import React, { Dispatch, SetStateAction, useState } from "react";
import s from "./ProgramCard.module.css";
import { Company, Project } from "../../../../../types/Company";
import { formatDateAndTime } from "../../../../../utils/dateActions";
import ProjectApi from "../../../../../api/projectApi";
import Notify, { SuccesNotify } from "../../../../../utils/toaster";
import { ReactComponent as DeleteIcon } from "../../../../../assets/Companies/delete.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/Companies/edit.svg";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import ModalWindow from "../../../../../components/ModalWindow/ModalWindow";
import CreateProgram from "../CreateProgram/CreateProgram";

type ProgramCardProps = {
  programData: Project;
  index: number;
  dataLength: number;
  setProgramsData: Dispatch<SetStateAction<Project[] | null>>;
  setSelectedProgram: Dispatch<SetStateAction<Project | null>>;
  selectedCompany: Company;
};

const ProgramCard = ({
  programData,
  index,
  dataLength,
  setProgramsData,
  selectedCompany,
  setSelectedProgram,
}: ProgramCardProps) => {
  const token = localStorage.getItem("token");
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [updateProjectModal, setUpdateProjectModal] = useState(false);
  const [actionLoaders, setActionLoaders] = useState({
    deleteProjectLoader: false,
  });

  const deleteProgram = async (projectId: string) => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, deleteProjectLoader: true }));
    const response = await ProjectApi.deleteProject(token, projectId);
    setActionLoaders((prev) => ({ ...prev, deleteProjectLoader: false }));
    if (response.status) {
      setProgramsData((prev) =>
        prev ? prev.filter((el) => el._id !== programData._id) : prev
      );
      setDeleteProjectModal(false);
      SuccesNotify("Project has been removed");
    }
  };

  return (
    <div
      className={s.container}
      style={{
        backgroundColor:
          (index + 1) % 2 === 0 ? "rgba(63, 176, 130, 0.1)" : "rgb(46, 46, 46)",
        borderRadius: index === dataLength - 1 ? "0 0 20px 20px" : "0",
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setSelectedProgram(programData);
        }}
        className={s.cardContent}
      >
        <div className={s.nameBlock}>{programData.title}</div>
        <div
          className={s.clientsLimitBlock}
          style={{
            color:
              programData.clients.length === programData.clientLimit
                ? "#e55a5a"
                : "var(--primary-color)",
          }}
        >
          {programData.clients.length + "/" + programData.clientLimit}
        </div>
        <div
          className={s.specLimitBlock}
          style={{
            color:
              programData.specialists.length === programData.specialistLimit
                ? "#e55a5a"
                : "var(--primary-color)",
          }}
        >
          {programData.specialists.length + "/" + programData.specialistLimit}
        </div>
        <div
          className={s.discountBlock}
          style={{
            color:
              programData.sessionDiscount === 0
                ? "#e55a5a"
                : "var(--primary-color)",
          }}
        >
          {programData.sessionDiscount === 0
            ? 0
            : "-" + programData.sessionDiscount + "%"}
        </div>
        <div className={s.isPrivateBlock}>
          <span
            className={
              programData.isPrivate ? s.privateBlock : s.notPrivateBlock
            }
          >
            {programData.isPrivate ? "Yes" : "No"}
          </span>
        </div>
        <div className={s.endDateBlock}>
          {
            formatDateAndTime(new Date(programData.endDate!), "en")
              .formattedDate
          }
          <div className={s.programActionBlock}>
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setUpdateProjectModal(true);
              }}
              className={s.editBtn}
            >
              <EditIcon className={s.editIcon} />
            </div>
            <div
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteProjectModal(true);
              }}
              className={s.deleteBtn}
            >
              <DeleteIcon className={s.deleteIcon} />
            </div>
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={updateProjectModal}
        setIsOpen={setUpdateProjectModal}
        maxWidth="fit-content"
      >
        <CreateProgram
          selectedCompany={selectedCompany}
          setProgramsData={setProgramsData}
          setProgramModalVisible={setUpdateProjectModal}
          isUpdateProgram
          programData={programData}
        />
      </ModalWindow>
      <ConfirmationModal
        confirmFunc={() => deleteProgram(programData._id)}
        confirmTitle="Are you sure you want to delete this project?"
        modalVisible={deleteProjectModal}
        setModalVisible={setDeleteProjectModal}
        loader={actionLoaders.deleteProjectLoader}
      />
    </div>
  );
};

export default ProgramCard;
