import React, { useEffect, useState } from "react";
import s from "./Dashboard.module.css";
import { StatisticResponse } from "../../types/Statistic";
import StatisticApi from "../../api/statisticApi";
import StatisticsCard from "./StatisticsCard/StatisticsCard";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";

const Dashboard = () => {
  const token = localStorage.getItem("token");
  const [statisticData, setStatisticData] = useState<
    { title: String; value: number; unit?: string }[] | null
  >(null);
  const [loader, setLoader] = useState({ mainLoader: false });

  useEffect(() => {
    if (!token) return;
    const makeAsync = async () => {
      setLoader((prev) => ({ ...prev, mainLoader: true }));
      const resposne = await StatisticApi.getAdminDashboardStats(token);
      setLoader((prev) => ({ ...prev, mainLoader: false }));
      if (resposne.status && resposne.statistics) {
        const statistictArray = [
          {
            title: "Clients Amount",
            value: resposne.statistics.defaultUsersAmount,
          },
          {
            title: "Specialists Amount",
            value: resposne.statistics.specialistUsersAmount,
          },
          {
            title: "Sessions Amount",
            value: resposne.statistics.sessionsAmount,
          },
          {
            title: "Completed Sessions Amount",
            value: resposne.statistics.completedSessionsAmount,
          },
          {
            title: "Sessions Average Duration",
            value: resposne.statistics.sessionsAverageDuration,
            unit: "(min)",
          },
        ];

        setStatisticData(statistictArray);
      }
    };
    makeAsync();
  }, [token]);
  if (loader.mainLoader)
    return (
      <div className={s.mainLoaderContainer}>
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );

  return (
    <div className={s.container}>
      {statisticData && (
        <div className={s.statsListBlock}>
          {statisticData.map((item, index) => (
            <div className={s.statisticCardBlock} key={index}>
              <StatisticsCard
                title={item.title as string}
                value={Math.round(item.value)}
                unit={item.unit}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
