import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import s from "./SelectedCompany.module.css";
import { Company } from "../../../types/Company";
import { ContextProvider } from "../../../contextProvider";
import CompanyPrograms from "./CompanyPrograms/CompanyPrograms";
import Participants from "./Participants/Participants";

type SelectedCompanyProps = {
  selectedCompanyData: Company;
  setSelectedCompany: Dispatch<SetStateAction<Company | null>>;
  setCompaniesData: Dispatch<SetStateAction<Company[] | null>>;
};

const SelectedCompany = ({
  selectedCompanyData,
  setSelectedCompany,
  setCompaniesData,
}: SelectedCompanyProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [activeTab, setActiveTab] = useState<"participants" | "programs">(
    "participants"
  );

  return (
    <div className={s.container}>
      <div className={s.tabsBlock}>
        <div
          className={
            activeTab === "participants" ? s.activeTab : s.notActiveTab
          }
          role="button"
          onClick={() => setActiveTab("participants")}
        >
          <span>Participants</span>
          <span></span>
        </div>
        <div
          className={activeTab === "programs" ? s.activeTab : s.notActiveTab}
          role="button"
          onClick={() => setActiveTab("programs")}
        >
          <span>Programs</span>
          <span></span>
        </div>
      </div>
      {activeTab === "participants" ? (
        <div>
          <Participants
            selectedCompany={selectedCompanyData}
            setSelectedCompany={setSelectedCompany}
            setCompaniesData={setCompaniesData}
          />
        </div>
      ) : (
        <CompanyPrograms selectedCompany={selectedCompanyData} />
      )}
    </div>
  );
};

export default SelectedCompany;
