import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import s from "./Profile.module.css";
import { SpecialistData } from "../../types/TypeSpecialist";
import SpecialistApi from "../../api/specialistsApi";
import SessionsApi from "../../api/sessionApi";
import { SessionComment } from "../../types/TypeSession";
import "swiper/css";
import "swiper/css/pagination";
import { formatFileSize, formatedFiles } from "../../utils/messageChat";
import { ReactComponent as FileIcon } from "../../assets/Profile/file-icon.svg";
import { TypeUserData } from "../../types/TypeUsers";
import { calculateAge } from "../../utils/setupProfileInfo";
import {
  CircularProgress,
  Pagination,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import { ReactSpoiler } from "react-simple-spoiler";
import countries from "i18n-iso-countries";
import { ReactComponent as TokenIcon } from "../../assets/Profile/specialist/heart-tick.svg";
import { ReactComponent as MessageIcon } from "../../assets/Profile/specialist/message.svg";
import { ReactComponent as CalendarIcon } from "../../assets/Profile/specialist/calendar.svg";
import { ReactComponent as PracticHoursIcon } from "../../assets/Profile/specialist/play-circle.svg";
import { ReactComponent as YearExpIcon } from "../../assets/Profile/specialist/year-exp.svg";
import { ReactComponent as FromIcon } from "../../assets/Profile/specialist/smart-home.svg";
import { ReactComponent as RatingIcon } from "../../assets/Profile/specialist/unlimited.svg";
import { ReactComponent as GenderIcon } from "../../assets/Profile/specialist/gender.svg";
import { ReactComponent as StarIcon } from "../../assets/Profile/specialist/star.svg";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import { ReactComponent as PlayBtnIcon } from "../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/Profile/specialist/verify.svg";
import { ReactComponent as EyeIcon } from "../../assets/Auth/eye.svg";
import { theme } from "../../utils/theme";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import UsersApi from "../../api/usersApi";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export type CertificatesData = {
  _id?: string | undefined;
  file: string;
  filename: string | undefined;
  type: string | undefined;
  isNewFile?: boolean;
  size: number | undefined;
};

type ProfileProps = {
  userId: string;
  isOpen: boolean;
  username: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPublicProfile: boolean;
};

const Profile = ({
  userId,
  isOpen,
  username,
  setIsOpen,
  isPublicProfile,
}: ProfileProps) => {
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const token = localStorage.getItem("token");
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [loaders, setLoaders] = useState({
    mainLoader: false,
    certificates: false,
  });
  const [navigateToRegModal, setNavigateToRegModal] = useState(false);
  const [profileUserData, setProfileUserData] = useState<
    (TypeUserData & { specialistId?: SpecialistData }) | undefined | null
  >();
  const [specialistData, setSpecialistData] = useState<SpecialistData | null>(
    null
  );
  const [commentsData, setCommentsData] = useState<SessionComment[]>([]);
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });

  const [certificatesPaggination, setCertificatesPaggination] = useState({
    page: 1,
    total: 0,
  });
  const [certificatesData, setCertificatesData] = useState<CertificatesData[]>(
    []
  );
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth <= 768);
  const [generalInfoData, setGeneralInfoData] = useState<
    { title: string; icon: any; value: string | number | undefined }[] | null
  >(null);

  const introVideoId =
    profileUserData?.specialistId &&
    profileUserData.specialistId.introductionVideo
      ? profileUserData.specialistId.introductionVideo
      : null;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const userName = pathParts[pathParts.length - 1];

  useEffect(() => {
    if (token && userName) {
      const makeAsync = async () => {
        const userDataPublicResponse = await UsersApi.getUserPublicInfo(
          username,
          "en"
        );
        const userDataResponse = await UsersApi.getUserProfileInfoById(
          token,
          userId
        );

        const availableUserData = !isPublicProfile
          ? userDataResponse.user
          : userDataPublicResponse.user;
        if (!userDataResponse.status || !userDataPublicResponse.status) {
          setIsOpen(false);
        }

        if (userDataResponse.status && availableUserData) {
          setProfileUserData(availableUserData);
          setSpecialistData(availableUserData.specialistId!);
        }
      };
      makeAsync();
    }
  }, [username, userId]);

  useEffect(() => {
    function handleResize() {
      setIsScreenSmall(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const img = imgRef.current;

    if (!video || !canvas || !img) return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();

      img.src = dataURL;
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [profileUserData, specialistData, canvasRef.current, imgRef.current]);

  useEffect(() => {
    if (!profileUserData || !specialistData) return;
    const price = specialistData.price;
    const languages = profileUserData.preferredLanguages!.join(", ");
    const practicHours = specialistData.practiceHours;
    const yearsExp = specialistData.yearsOfExperience;
    const location = profileUserData.location;
    const age = calculateAge(new Date(profileUserData.dateOfBirth!));
    const rating = specialistData.rating?.toFixed(1);
    //@ts-expect-error
    const gender = profileUserData.gender.labels.find(
      //@ts-expect-error
      (item) => item.language === "en"
    ).text;

    const generalInfoElementData = [
      {
        title: "Per session",
        icon: TokenIcon,
        value: price,
      },
      {
        title: "Session Language",
        icon: MessageIcon,
        value: languages,
      },
      {
        title: "Practice hours",
        icon: PracticHoursIcon,
        value: practicHours + "+",
      },
      {
        title: "Years experience",
        icon: YearExpIcon,
        value: yearsExp + "+",
      },
      {
        title: "Gender",
        icon: GenderIcon,
        value: gender,
      },
      {
        title: "From",
        icon: FromIcon,
        value: location,
      },
      {
        title: "Age",
        icon: CalendarIcon,
        value: age,
      },
      {
        title: "Rating",
        icon: RatingIcon,
        value: rating,
      },
    ];
    setGeneralInfoData(generalInfoElementData);
  }, [profileUserData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        profileUserData &&
        profileUserData.specialistId &&
        profileUserData.specialistId._id &&
        profileUserData._id
      ) {
        const commentsResponse = await SessionsApi.getSpecialistsComments(
          token!,
          profileUserData?._id,
          0,
          10
        );

        if (commentsResponse.status) setCommentsData(commentsResponse.comments);
        const certificatesAmount = 2;
        const page = certificatesPaggination.page;
        setLoaders((prev) => ({ ...prev, certificates: true }));
        const certificatesResponse =
          await SpecialistApi.getSpecialistCertificates(
            token!,
            profileUserData.specialistId._id,
            page,
            certificatesAmount
          );

        setLoaders((prev) => ({ ...prev, certificates: false }));
        if (certificatesResponse.status && certificatesResponse.certificates) {
          setCertificatesPaggination((prev) => ({
            ...prev,
            total: Math.ceil(certificatesResponse.total / 2),
          }));
          const files = formatedFiles(certificatesResponse.certificates);
          const certificatesArr = files.map((item, index) => ({
            _id:
              certificatesResponse.certificates &&
              certificatesResponse.certificates[index]._id,
            filename:
              certificatesResponse.certificates &&
              certificatesResponse.certificates[index].filename,
            file: item,
            type:
              certificatesResponse.certificates &&
              certificatesResponse.certificates[index].type,
            size:
              certificatesResponse.certificates &&
              certificatesResponse.certificates[index].size,
          }));

          setCertificatesData(certificatesArr);
        }
      }
    };
    makeAsync();
  }, [certificatesPaggination.page, profileUserData]);

  const previewFileHandler = (file: string, type: string) => {
    if (type === "pdf") {
      setPreviewFile({ imageFile: null, pdfFile: file });
    } else {
      setPreviewFile({ imageFile: file, pdfFile: null });
    }
    setPreviewFileModal(true);
  };

  if (loaders.mainLoader || !profileUserData) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className={s.container} id="profile-content">
      <div className={s.headBlock}>
        <div className={s.headNameBlock}>
          <div className={s.mobileAvatarBlock}>
            <CircleAvatar
              userId={profileUserData?._id || ""}
              name={profileUserData?.name || ""}
              surname={profileUserData?.surname || ""}
              height="110px"
              width="110px"
              fontSize="52px"
              marginRight="20px"
            />
          </div>
          <div className={s.nameBlock}>
            <span className={s.nameTitle}>
              {profileUserData?.name} {profileUserData?.surname}
              {profileUserData?.specialistId &&
                profileUserData.specialistId.isVerified && (
                  <VerifiedIcon className={s.verifyIcon} />
                )}
            </span>
            <span className={s.mainSpecTitle}>
              {specialistData
                ? specialistData.mainSpecializations[0].labels.find(
                    (item) => item.language === "en" // change to selected language
                  )?.text
                : ""}
            </span>
            <span className={s.subSpecTitle}>
              {specialistData
                ? specialistData?.subSpecializations
                    .map(
                      (item, index) =>
                        item.labels.find((el) => el.language === "en")?.text // change to selected language
                    )
                    .join(", ")
                : ""}
            </span>
          </div>
        </div>
      </div>
      <div className={s.mainInfoBlock}>
        <div className={s.leftProfileBlock}>
          <div className={s.generalInfoBlock}>
            {generalInfoData &&
              generalInfoData.map((item, index) => (
                <div className={s.generalInfoElement} key={index}>
                  <item.icon className={s.genderalIcon} />
                  <div className={s.generalTitleBlock}>
                    <span>{item.title}</span>
                    <span>{item.value}</span>
                  </div>
                </div>
              ))}
          </div>
          {isScreenSmall && (
            <div className={s.mobileVideoEducationBlock}>
              {introVideoId ? (
                <div className={s.previewVideoBlock}>
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <img
                    ref={imgRef}
                    alt="First frame of video"
                    className={s.previewVideoImg}
                  />
                  <PlayBtnIcon
                    className={s.playBtn}
                    onClick={() => setVideoModalOpen(true)}
                  />
                </div>
              ) : null}
              {certificatesData && certificatesData.length ? (
                <div className={s.educationBlock}>
                  <h4 className={s.blockLable}>Education</h4>
                  {loaders.certificates ? (
                    <div className={s.educationLoaderBlock}>
                      <ThemeProvider theme={theme}>
                        <CircularProgress size={50} color="primary" />
                      </ThemeProvider>
                    </div>
                  ) : (
                    certificatesData.map((item, index) => (
                      <div
                        key={index}
                        className={s.educationElement}
                        style={{
                          marginBottom:
                            index < certificatesData.length - 1 ? "10px" : 0,
                        }}
                      >
                        <div className={s.leftEducationElContent}>
                          <div className={s.educationIconBlock}>
                            <FileIcon className={s.fileIcon} />
                          </div>
                          <div className={s.certificateTitleBlock}>
                            <Tooltip
                              title={
                                item.filename && item.filename.length > 30
                                  ? item.filename
                                  : ""
                              }
                            >
                              <span className={s.certificateTitle}>
                                {item.filename && item.filename.length > 30
                                  ? item.filename?.slice(0, 30) + "..."
                                  : item.filename}
                              </span>
                            </Tooltip>
                            <span>{formatFileSize(item.size!)}</span>
                          </div>
                        </div>
                        <EyeIcon
                          className={s.eyeIcon}
                          onClick={() =>
                            previewFileHandler(item.file, item.type!)
                          }
                        />
                      </div>
                    ))
                  )}

                  <div className={s.pagginationBlock}>
                    <ThemeProvider theme={theme}>
                      <Pagination
                        page={certificatesPaggination.page}
                        count={certificatesPaggination.total}
                        color="primary"
                        onChange={(e, page) =>
                          setCertificatesPaggination((prev) => ({
                            ...prev,
                            page: page,
                          }))
                        }
                      />
                    </ThemeProvider>
                  </div>
                </div>
              ) : null}
            </div>
          )}
          {specialistData?.aboutMyself &&
          specialistData?.aboutMyself[0] !== "" ? (
            <div className={s.aboutTitleBlock}>
              <h4 className={s.blockLable}>About me</h4>
              <ReactSpoiler
                collapsedSize={100}
                showMoreComponent={
                  <div className={s.showMoreBtn}>
                    <p>Show more</p>
                  </div>
                }
                showLessComponent={
                  <div className={s.showMoreBtn}>
                    <p>Show less</p>
                  </div>
                }
              >
                <p className={s.aboutTitle}>{specialistData.aboutMyself}</p>
              </ReactSpoiler>
            </div>
          ) : null}
          {specialistData?.aboutTherapy &&
          specialistData?.aboutTherapy[0] !== "" ? (
            <div className={s.aboutTitleBlock}>
              <h4 className={s.blockLable}>How session goes with me</h4>
              <ReactSpoiler
                collapsedSize={100}
                showMoreComponent={
                  <div className={s.showMoreBtn}>
                    <p>Show more</p>
                  </div>
                }
                showLessComponent={
                  <div className={s.showMoreBtn}>
                    <p>Show less</p>
                  </div>
                }
              >
                <p className={s.aboutTitle}>{specialistData.aboutTherapy}</p>
              </ReactSpoiler>
            </div>
          ) : null}
          {commentsData && commentsData.length ? (
            <div className={s.reviewsBlock}>
              <h4 className={s.blockLable}>Reviews ({commentsData.length})</h4>
              <div className={s.commentsListBlock}>
                {commentsData.map((item, index) => (
                  <div className={s.commentCard} key={index}>
                    <div className={s.ratingBlock}>
                      {Array.from({ length: item.rating }).map((el, index) => (
                        <StarIcon className={s.starIcon} key={index} />
                      ))}
                      <span>{item.rating + ".0"}</span>
                    </div>
                    <div className={s.commentTextBlock}>
                      <p className={s.commentText}>{item.customComment}</p>

                      {item.defaultComments.map((item) => (
                        <p key={item.comment} className={s.commentText}>
                          {
                            item.labels.find(
                              (lable) => lable.language === "en" // change to selected language
                            )?.text
                          }
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {!isScreenSmall && (
          <div className={s.rightProfileBlock}>
            {introVideoId ? (
              <div className={s.previewVideoBlock}>
                <canvas ref={canvasRef} style={{ display: "none" }} />
                <img
                  ref={imgRef}
                  alt="First frame of video"
                  className={s.previewVideoImg}
                />
                <PlayBtnIcon
                  className={s.playBtn}
                  onClick={() => setVideoModalOpen(true)}
                />
              </div>
            ) : null}
            {certificatesData && certificatesData.length ? (
              <div className={s.educationBlock}>
                <h4 className={s.blockLable}>Education</h4>
                {loaders.certificates ? (
                  <div className={s.educationLoaderBlock}>
                    <ThemeProvider theme={theme}>
                      <CircularProgress size={50} color="primary" />
                    </ThemeProvider>
                  </div>
                ) : (
                  certificatesData.map((item, index) => (
                    <div
                      key={index}
                      className={s.educationElement}
                      style={{
                        marginBottom:
                          index < certificatesData.length - 1 ? "10px" : 0,
                      }}
                    >
                      <div className={s.leftEducationElContent}>
                        <div className={s.educationIconBlock}>
                          <FileIcon className={s.fileIcon} />
                        </div>
                        <div className={s.certificateTitleBlock}>
                          <Tooltip
                            title={
                              item.filename && item.filename.length > 30
                                ? item.filename
                                : ""
                            }
                          >
                            <span className={s.certificateTitle}>
                              {item.filename && item.filename.length > 30
                                ? item.filename?.slice(0, 30) + "..."
                                : item.filename}
                            </span>
                          </Tooltip>
                          <span>{formatFileSize(item.size!)}</span>
                        </div>
                      </div>
                      <EyeIcon
                        className={s.eyeIcon}
                        onClick={() =>
                          previewFileHandler(item.file, item.type!)
                        }
                      />
                    </div>
                  ))
                )}

                <div className={s.pagginationBlock}>
                  <ThemeProvider theme={theme}>
                    <Pagination
                      page={certificatesPaggination.page}
                      count={certificatesPaggination.total}
                      color="primary"
                      onChange={(e, page) =>
                        setCertificatesPaggination((prev) => ({
                          ...prev,
                          page: page,
                        }))
                      }
                    />
                  </ThemeProvider>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <video
        ref={videoRef}
        src={`${connectionString}specialists/getSpecialistIntroVideo/${userId}`}
        style={{ display: "none" }}
        crossOrigin="anonymous"
      />
      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={videoModalOpen}
        setIsOpen={setVideoModalOpen}
        bgColor="transparent"
        width="fit-content"
      >
        <video
          src={`${connectionString}specialists/getSpecialistIntroVideo/${userId}`}
          crossOrigin="anonymous"
          controls
          autoPlay
          className={s.video}
        />
      </ModalWindow>
    </div>
  );
};

export default Profile;
