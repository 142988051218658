import React from "react";
import UsersApi from "../../api/usersApi";

const Users = () => {
  const fixAvatars = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const res = await UsersApi.fixUserAvatars(token);
    console.log(res);
  };

  return (
    <div style={{ color: "white" }}>
      Users <button onClick={fixAvatars}>Fix avatars</button>
    </div>
  );
};

export default Users;
