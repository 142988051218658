import { features } from "process";
import SubscriptionsAPI from "../api/subscriptionsApi";
import { InfoCard } from "../pages/Subscriptions/NewSubscription/NewSubscription";
import {
  Subscription,
  SubscriptionFeatures,
  SubscriptionInfo,
} from "../types/Subscription";
import Notify, { SuccesNotify } from "./toaster";

export const createSubscription = async (
  token: string,
  newSubscription: Omit<Subscription, "_id" | "shortInfoList">,
  shortInfoList: InfoCard[]
) => {
  const createSubscriptionResponse = await SubscriptionsAPI.createSubscription(
    token,
    newSubscription
  );
  if (
    createSubscriptionResponse.status &&
    createSubscriptionResponse.subscription
  ) {
    const subscriptionId = createSubscriptionResponse.subscription._id;
    for (const item of shortInfoList) {
      const subscriptionInfo = {
        ...item,
        title: item.title.find((el) => el.language === "en")?.text!,
        description: item.description.find((el) => el.language === "en")?.text!,
        features: undefined,
        descriptionLabels: item.description,
        titleLabels: item.title,
      };
      const createShortInfoResponse = await createSubscriptionInfo(
        token,
        //@ts-expect-error
        subscriptionInfo,
        subscriptionId
      );
      if (
        (!createShortInfoResponse.status ||
          !createShortInfoResponse.shortInfo) &&
        createShortInfoResponse.message
      ) {
        Notify(createShortInfoResponse.message);
        break;
      }
      const features = item.features.map((featureEl) => ({
        ...featureEl,
        title: featureEl.title.find((el) => el.language === "en")?.text!,
        description: featureEl.description.find((el) => el.language === "en")
          ?.text!,
        descriptionLabels: featureEl.description,
        titleLabels: featureEl.title,
      }));

      const createFeaturesResponse = await createSubscriptionFeatures(
        token,
        createShortInfoResponse.shortInfo!._id,
        //@ts-expect-error
        features
      );
      if (
        (!createFeaturesResponse.status || !createFeaturesResponse.features) &&
        createFeaturesResponse.message
      ) {
        Notify(createFeaturesResponse.message);
        break;
      }
    }

    return true;
  }
};
async function createSubscriptionInfo(
  token: string,
  subscriptionInfo: Omit<SubscriptionInfo, "_id" | "features" | "icon"> & {
    icon?: File;
  },

  subscriptionId: string
) {
  const formData = new FormData();
  if (subscriptionInfo.icon) {
    formData.append("icon", subscriptionInfo.icon);
  }

  const newShortInfo = {
    ...subscriptionInfo,
    icon: undefined,
  };
  formData.append("newShortInfo", JSON.stringify(newShortInfo));

  const response = await SubscriptionsAPI.createSubscriptionShortInfo(
    token,
    formData,
    subscriptionId
  );
  return response;
}

async function createSubscriptionFeatures(
  token: string,
  subscriptionShortInfoId: string,
  features: (Omit<SubscriptionFeatures, "_id" | "icon"> & { icon?: File })[]
) {
  const formData = new FormData();
  const featuresWithoutFiles = features.map((feature) => {
    if (feature.icon) {
      formData.append(`icons`, feature.icon);
      return {
        ...feature,
        icon: feature.icon.name,
      };
    } else {
      return feature;
    }
  });
  formData.append("features", JSON.stringify(featuresWithoutFiles));

  const response = await SubscriptionsAPI.createSubscriptionFeatures(
    token,
    formData,
    subscriptionShortInfoId
  );
  return response;
}
