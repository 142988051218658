import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SessionComment } from "../types/TypeSession";
import { StatisticResponse } from "../types/Statistic";

const { stats } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "statistics/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class StatisticApi {
  static async getAdminDashboardStats(token: string): Promise<
    TypeDefaultResponse & {
      statistics: StatisticResponse;
    }
  > {
    return await ky
      .get(`${baseURL}${stats.getAdminDashboardStats}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
