import React, { useContext, useEffect, useState } from "react";
import s from "./ClientCard.module.css";
import { ContextProvider } from "../../../../../../contextProvider";
import UsersApi from "../../../../../../api/usersApi";
import CircleAvatar from "../../../../../../components/CircleAvatar/CircleAvatar";
import { calculateAge } from "../../../../../../utils/general";

type ClientCardProps = {
  bgColor: string;
  userId: string;
};

type ProgramUserData = {
  name: string;
  surname: string;
  email: string;
  dateOfBirth: string;
  location: string;
};

const ClientCard = ({ bgColor, userId }: ClientCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [programUserData, setProgramUserData] =
    useState<ProgramUserData | null>(null);
  const [actionLoaders, setActionLoaders] = useState({ laodUserData: false });

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const selectUserFields = "name surname email dateOfBirth location";
      setActionLoaders((prev) => ({ ...prev, laodUserData: true }));
      const response = await UsersApi.getUserSelectedFields(
        token,
        userId,
        selectUserFields,
        "price"
      );
      if (response.status && response.userData) {
        const responseUserData = {
          name: response.userData.name!,
          surname: response.userData.surname!,
          email: response.userData.email!,
          dateOfBirth: response.userData.dateOfBirth!.toString(),
          location: response.userData.location!,
        };
        setProgramUserData(responseUserData);
      }
      setActionLoaders((prev) => ({ ...prev, laodUserData: false }));
    };
    makeAsync();
  }, [token]);

  return (
    <div className={s.container} style={{ backgroundColor: bgColor }}>
      <div className={s.mainUserInfoBlock}>
        <CircleAvatar userId={userId} width={"50px"} height={"50px"} />

        <div className={s.mainUserTitleInfo}>
          <span>
            {programUserData &&
              programUserData.name + " " + programUserData.surname}
          </span>
        </div>
      </div>

      <div className={s.emailUserBlock}>
        {programUserData && programUserData.email}
      </div>
      <div className={s.ageUserBlock}>
        {programUserData && calculateAge(new Date(programUserData.dateOfBirth))}
      </div>
      <div className={s.locationuserBlock}>
        {programUserData && programUserData.location}
      </div>
    </div>
  );
};

export default ClientCard;
