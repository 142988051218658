import { Dispatch, SetStateAction, createContext } from "react";
import { TypeUserData } from "./types/TypeUsers";

export type TypeContextProvider = {
  isAuth: boolean;
  setIsAuth: Dispatch<SetStateAction<boolean>>;
  userData: TypeUserData | null | undefined;
};

export const ContextProvider = createContext<TypeContextProvider>(
  {} as TypeContextProvider
);
