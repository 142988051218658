import React, { Dispatch, SetStateAction, useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./RangeSlider.css";

type StateType = {
  value: { from: number; to: number };
  range: { min: number; max: number };
};

function valuetext(value: number) {
  return `${value}°C`;
}

type RangeSliderProps = {
  value: number[];
  setValue: Dispatch<SetStateAction<StateType>>;
  minDistance: number;
  min: number;
  max: number;
};

const RangeSlider = ({
  value,
  setValue,
  minDistance,
  min,
  max,
}: RangeSliderProps) => {
  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue((prev) => ({
        ...prev,
        value: {
          from: Math.min(newValue[0], value[1] - minDistance),
          to: value[1],
        },
      }));
    } else {
      setValue((prev) => ({
        ...prev,
        value: {
          from: value[0],
          to: Math.max(newValue[1], value[0] + minDistance),
        },
      }));
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        getAriaLabel={() => "Minimum distance"}
        min={min}
        max={max}
        value={value}
        onChange={handleChange1}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
      />
    </Box>
  );
};

export default RangeSlider;
