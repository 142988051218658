import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import s from "./Main.module.css";
import Users from "../../pages/Users/Users";
import Specialists from "../../pages/Specialists/Specialists";
import Companies from "../../pages/Companies/Companies";
import SideBar from "../../components/SideBar/SideBar";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Subscriptions from "../../pages/Subscriptions/Subscriptions";

const Main = () => {
  return (
    <div className={s.container}>
      <div className={s.sideBarMenu}>
        <SideBar />
      </div>
      <div className={s.mainContent}>
        <Routes>
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/specialists" element={<Specialists />} />
          <Route path="/companies/*" element={<Companies />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
        </Routes>
      </div>
    </div>
  );
};

export default Main;
