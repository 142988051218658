import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import s from "./ProgramInfo.module.css";
import {
  Company,
  Project,
  Roles,
  TypeCompanyEmployee,
} from "../../../../../types/Company";
import { ReactComponent as BackIcon } from "../../../../../assets/Specialists/chevronLeft.svg";
import ProjectApi from "../../../../../api/projectApi";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import ClientCard from "./ClientCard/ClientCard";
import SpecialistCardBlock from "./SpecialistCardBlock/SpecialistCardBlock";
import ModalWindow from "../../../../../components/ModalWindow/ModalWindow";
import CompanyApi from "../../../../../api/companyApi";
import { SuccesNotify } from "../../../../../utils/toaster";
import Loader from "../../../../../components/Loader/Loader";
import { ToastContainer } from "react-toastify";

type ProgramInfoType = {
  companyData: Company;
  selectedProgram: Project;
  setSelectedProgram: Dispatch<SetStateAction<Project | null>>;
};

const ProgramInfo = ({
  selectedProgram,
  setSelectedProgram,
  companyData,
}: ProgramInfoType) => {
  const token = localStorage.getItem("token");
  const [projectSpecialists, setProjectSpecialists] = useState<
    TypeCompanyEmployee[]
  >([]);
  const [projectClients, setProjectClients] = useState<TypeCompanyEmployee[]>(
    []
  );
  const [activeTableTab, setActiveTableTab] = useState<
    "specialists" | "clients"
  >("specialists");
  const [inviteSpecialistModal, setInviteSpecialistModal] = useState(false);
  const [availableCompanySpecialists, setAvailableCompanySpecialists] =
    useState<TypeCompanyEmployee[]>([]);
  const [specToInvite, setSpecToInvite] = useState<string[]>([]);
  const [actionLoaders, setActionLoaders] = useState({ inviteLoader: false });

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const response = await ProjectApi.getProjectParticipants(
        token,
        selectedProgram._id
      );

      if (response.status && response.participants) {
        const specialists = response.participants.filter(
          //@ts-expect-error
          (el) => el.role === "specialist"
        );

        setProjectSpecialists(specialists);
        const clients = response.participants.filter(
          //@ts-expect-error
          (el) => el.role !== "specialist"
        );
        setProjectClients(clients);
      }
    };
    makeAsync();
  }, [token, selectedProgram]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const response = await CompanyApi.getCompanyParticipants(
        token,
        companyData._id
      );

      if (response.status && response.participants) {
        const specToProjectId = projectSpecialists.map((item) => item.user._id);
        const companySpecialists = response.participants.filter(
          (user) => user.role.value === "specialist"
        );

        const filteredSpecialists = companySpecialists.filter(
          (spec) => !specToProjectId.includes(spec.user._id)
        );
        setAvailableCompanySpecialists(filteredSpecialists);
      }
    };
    makeAsync();
  }, [companyData, token, projectSpecialists]);

  const checkAlreadySelectSpec = (email: string) => {
    const specIdsArr = specToInvite.map((selectSpec) => selectSpec);
    return specIdsArr.includes(email);
  };

  const inviteHandler = async () => {
    if (!token) return;
    const payload = {
      emails: specToInvite,
      projectId: selectedProgram._id,
      role: Roles.SPECIALIST as Roles.SPECIALIST,
    };

    setActionLoaders((prev) => ({ ...prev, inviteLoader: true }));
    const response = await ProjectApi.inviteUserToProject(token, payload);

    setActionLoaders((prev) => ({ ...prev, inviteLoader: false }));
    if (response[0].status) {
      SuccesNotify("Invitation sent");
      setSpecToInvite([]);
      setInviteSpecialistModal(false);
    }
  };
  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <div
          className={s.backBtn}
          role="button"
          onClick={() => setSelectedProgram(null)}
        >
          <BackIcon className={s.backIcon} />
          Back to all programs
        </div>
      </div>
      <div className={s.generalInfoBlock}>
        <h2>{selectedProgram.title}</h2>
        <div className={s.descriptionBlock}>
          <span>Descriprion</span>
          <span>{selectedProgram.description}</span>
        </div>
        <div className={s.priceBlock}>
          <span>Discount: </span>
          <span
            className={
              selectedProgram.sessionDiscount === 0
                ? s.discoutnZero
                : s.discountValue
            }
          >
            {selectedProgram.sessionDiscount === 0
              ? "0"
              : "-" + selectedProgram.sessionDiscount + "%"}
          </span>
        </div>
      </div>
      <div className={s.tableBlock}>
        <div className={s.headTableBlock}>
          <div className={s.tabsBlock}>
            <div
              className={`${s.specTab} ${
                activeTableTab === "specialists" ? s.activeTab : s.notActivetab
              }`}
              onClick={() => setActiveTableTab("specialists")}
            >
              Specialists
            </div>
            <div
              className={`${s.clientsTab} ${
                activeTableTab === "clients" ? s.activeTab : s.notActivetab
              }`}
              onClick={() => setActiveTableTab("clients")}
            >
              Clients
            </div>
          </div>
          <div className={s.tableTitleBlock}>
            {activeTableTab === "specialists" ? "Specialists" : "Clients"}
          </div>
          <div className={s.tableActionBlock}>
            {activeTableTab === "specialists" && (
              <StandartButton
                buttonTitle={
                  activeTableTab === "specialists"
                    ? "Invite specialists"
                    : "Invite clients"
                }
                action={() => setInviteSpecialistModal(true)}
                height="40px"
              />
            )}
          </div>
        </div>
        {activeTableTab === "clients" ? (
          <div className={s.specialistsTableLableBlock}>
            <span>Name</span>
            <span>Email</span>
            <span>Age</span>
            <span>Location</span>
          </div>
        ) : (
          <div className={s.clientsTableLableBlock}>
            <span>Name</span>
            <span>Email</span>
            <span>Specialization</span>
            <span>Price</span>
          </div>
        )}
        {activeTableTab === "clients" ? (
          <div className={s.clientsListBlock}>
            {projectClients.length ? (
              projectClients.map((item, index) => (
                <ClientCard
                  key={index}
                  bgColor={
                    (index + 1) % 2 !== 0
                      ? "rgba(63, 176, 130, 0.1)"
                      : "rgb(46, 46, 46)"
                  }
                  userId={item.user._id}
                />
              ))
            ) : (
              <div className={s.noDataBlock}>
                <h3>No clients in this project</h3>
              </div>
            )}
          </div>
        ) : (
          <div className={s.specialistsListBlock}>
            {projectSpecialists.length ? (
              projectSpecialists.map((item, index) => (
                <SpecialistCardBlock
                  key={index}
                  bgColor={
                    (index + 1) % 2 !== 0
                      ? "rgba(63, 176, 130, 0.1)"
                      : "rgb(46, 46, 46)"
                  }
                  userId={item.user._id}
                  discount={selectedProgram.sessionDiscount}
                  projectId={selectedProgram._id}
                  setProjectSpecialists={setProjectSpecialists}
                />
              ))
            ) : (
              <div className={s.noDataBlock}>
                <h3>No specialist in this project</h3>
              </div>
            )}
          </div>
        )}
      </div>
      <ModalWindow
        isOpen={inviteSpecialistModal}
        setIsOpen={setInviteSpecialistModal}
        maxWidth="fit-content"
        padding="20px"
      >
        {actionLoaders.inviteLoader ? (
          <div className={s.loaderContainer}>
            <Loader size={100} />
          </div>
        ) : (
          <div className={s.inviteSpecModal}>
            <h2 className={s.inviteHeadTitle}>Select specialists to invite</h2>
            <div className={s.inviteSpecialistsList}>
              {availableCompanySpecialists.length ? (
                availableCompanySpecialists.map((item, index) => (
                  <div
                    className={s.inviteSpecElement}
                    key={index}
                    style={{
                      backgroundColor: checkAlreadySelectSpec(item.user.email)
                        ? "var(--primary-hover)"
                        : "rgb(46, 46, 46)",
                    }}
                    onClick={() =>
                      checkAlreadySelectSpec(item.user.email)
                        ? setSpecToInvite((prev) =>
                            prev.filter((el) => el !== item.user.email)
                          )
                        : setSpecToInvite((prev) => [...prev, item.user.email])
                    }
                  >
                    <SpecialistCardBlock
                      bgColor={"transparent"}
                      userId={item.user._id}
                      discount={selectedProgram.sessionDiscount}
                      projectId={selectedProgram._id}
                      setProjectSpecialists={setProjectSpecialists}
                      isInviteCard
                    />
                  </div>
                ))
              ) : (
                <div className={s.noDataBlock}>
                  <h3>No available specialist to invite</h3>
                </div>
              )}
            </div>
            <div className={s.actionBlock}>
              <StandartButton
                action={inviteHandler}
                buttonTitle="Invite"
                width="200px"
                disabled={specToInvite.length ? false : true}
              />
              <button
                className={s.cancelBtn}
                onClick={() => {
                  setSpecToInvite([]);
                  setInviteSpecialistModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </ModalWindow>
    </div>
  );
};

export default ProgramInfo;
