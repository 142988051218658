import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Authorization from "../../pages/Authorization/Authorization";
import s from "./Auth.module.css";

const Auth = () => {
  return (
    <div className={s.authContainer}>
      <Routes>
        <Route path="*" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Authorization />} />
      </Routes>
    </div>
  );
};

export default Auth;
