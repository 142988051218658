import React, { useContext, useEffect, useRef, useState } from "react";
import s from "./Specialists.module.css";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import ReactFlagsSelect from "react-flags-select";
import countries from "i18n-iso-countries";
import { ReactComponent as CloseIcon } from "../../assets/General/close.svg";
import RangeSlider from "../../components/RangeSlider/RangeSlider";
import { ReactComponent as FilterIcon } from "../../assets/Specialists/filter-icon.svg";
import MagnifierIcon from "../../assets/Specialists/magnifier.svg";
import {
  getExtraElements,
  removeFilterElements,
} from "../../utils/specialistFilter";
import { ContextProvider } from "../../contextProvider";
import SpecialistApi from "../../api/specialistsApi";
import getAvailableParams from "../../utils/getAvailableParams";
import { TypeUserData } from "../../types/TypeUsers";
import SpecialistTable from "../../components/SpecialistTable/SpecialistTable";
import AnimateHeight from "react-animate-height";
import { ReactComponent as ChevronDownIcon } from "../../assets/General/chevronDown.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/Specialists/chevronLeft.svg";
import { ReactComponent as VerifyIcon } from "../../assets/Specialists/verify.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import { calculateAge } from "../../utils/general";
import SessionsApi from "../../api/sessionApi";
import { SessionComment } from "../../types/TypeSession";
import CommentCard from "./CommentCard/CommentCard";
import UsersApi from "../../api/usersApi";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import Profile from "../Profile/Profile";
import ScrollableModal from "../../components/ScrollableModal/ScrollableModal";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const genderSelectData = [
  {
    value: "Male",
    lable: "Male",
  },
  {
    value: "Female",
    lable: "Female",
  },
  {
    value: "Non-binary",
    lable: "Non-binary",
  },
];
const statusSelectData = [
  {
    value: undefined,
    lable: "All",
  },
  {
    value: true,
    lable: "Verified",
  },
  {
    value: false,
    lable: "Unverified",
  },
];
type SelectData = {
  value: string;
  lable: string;
};
type SpecializationsState = {
  mainSpecData: SelectData[];
  subSpecData?: SelectData[];
  selectedMainSpec?: string | string[];
  selectedSubSpec?: string | string[];
};

const Specialists = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const filterRef = useRef<any>(null);
  const [search, setSearch] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [availableCountry, setAvailableCountry] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });
  const [availableCity, setAvailableCity] = useState<
    { id: number; lable: string }[]
  >([]);
  const [selectedCity, setSelectedCity] = useState<string | string[]>([]);
  const [languagesList, setLanguagesList] = useState<
    { value: string; lable: string }[]
  >([]);
  const [selectedLangs, setSelectedLangs] = useState<string[]>([]);
  const [ageRangeFilter, setAgeRangeFilter] = useState({
    value: { from: 0, to: 1000 },
    range: { min: 18, max: 100 },
  });
  const [priceRangeFilter, setPriceRangeFilter] = useState({
    value: { from: 0, to: 1000 },
    range: { min: 1, max: 1000 },
  });
  const [filterCounter, setFilterCounter] = useState(0);
  const [filterRanges, setFilterRanges] = useState({
    age: { min: 0, max: 0 },
    price: { min: 0, max: 0 },
  });
  const [specializations, setSpecializations] = useState<SpecializationsState>({
    mainSpecData: [],
  });
  const [availableGenders, setAvailableGenders] = useState<
    | {
        value: string;
        lable: string;
      }[]
    | null
  >(null);
  const [genderFilterValue, setGenderFilterValue] = useState<string | string[]>(
    []
  );
  /*   const [pagginatuions, setPagginations] = useState({
    total: 0,
    currentPage: 1,
  }); */
  const [isLoader, setIsLoader] = useState(false);
  const [specialistData, setSpecialistData] = useState<
    TypeUserData[] | undefined
  >();
  const [page, setPage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [verifiedStatus, setVerifiedStatus] = useState<boolean | undefined>(
    undefined
  );
  const [commentsData, setCommentsData] = useState<SessionComment[]>([]);
  const [statusFilterCollapsed, setStatusFilterCollapsed] = useState(true);
  const [selectedSpecialist, setSelectedSpecialist] = useState<
    TypeUserData | undefined
  >();
  const [selectNavigationElement, setSelectNavigationElement] = useState<
    "info" | "complaints" | "settings"
  >("info");
  const [selectStatusCollapsed, setSelectStatusCollapsed] = useState({
    verification: true,
    changes: true,
  });
  const [avatars, setAvatars] = useState<{ [userId: string]: string }>({});
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isUpdatedProfileModalOpen, setIsUpdatedProfileModalOpen] =
    useState(false);

  const handleClickOutside = (event: any) => {
    const isInsideFilterMenu =
      filterRef.current && filterRef.current.contains(event.target);
    const isInsideSelect = event.target.closest(".multi-select-menu");
    const isInsideSelect2 = event.target.closest(".MuiModal-backdrop");

    if (
      !isInsideFilterMenu &&
      !isInsideSelect &&
      !isInsideSelect2 &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setIsFilterOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && specialistData && specialistData.length > 0) {
        /* socket.off(chat.newDialogMessage, handleMessage);
        socket.on(chat.newDialogMessage, handleMessage); */

        const promises = specialistData.map((spec) =>
          UsersApi.getUserAvatarAndUsername(token, spec._id)
        );
        const responses = await Promise.all(promises);
        const avatarsData = responses.reduce((acc, response) => {
          if (response.avatar) {
            acc[response._id] = response.avatar;
          }
          return acc;
        }, {} as { [userId: string]: string });

        setAvatars(avatarsData);
      }
    };
    makeAsync();
  }, [specialistData]);

  const checkRangeValue = (
    value1: number,
    value2: number,
    max: number,
    min: number
  ): boolean => {
    if (
      value1 > value2 ||
      value1 < min ||
      value2 > max ||
      value2 < min ||
      value1 > max
    )
      return false;
    else return true;
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData) {
        const payload = {
          userId: userData._id,
          filters: {
            name: search.length > 0 ? search : "",
            country:
              selectedCountry && selectedCountry.title
                ? [selectedCountry.title]
                : undefined,
            city:
              selectedCity && selectedCity.length ? selectedCity : undefined,
            gender: genderFilterValue.length ? genderFilterValue : undefined,
            age: {
              from: ageRangeFilter.value.from,
              to: ageRangeFilter.value.to,
            },
            preferredLanguages: selectedLangs.length
              ? selectedLangs
              : undefined,
            specialistId: {
              subSpecializations:
                specializations.selectedSubSpec &&
                specializations.selectedSubSpec.length
                  ? specializations.selectedSubSpec
                  : undefined,
              mainSpecializations:
                specializations.selectedMainSpec &&
                specializations.selectedMainSpec.length
                  ? specializations.selectedMainSpec
                  : undefined,
              price: {
                from: priceRangeFilter.value.from,
                to: priceRangeFilter.value.to,
              },
              isVerified: verifiedStatus,
            },
            page: page + 1,
            limit: rowsPerPage,
          },
        };
        setIsLoader(true);

        const response = await SpecialistApi.getFilteredSpecialists(
          token,
          payload
        );

        if (response.paramsAvailable && response.paramsAvailable.totalAmount) {
          setTotalAmount(response.paramsAvailable.totalAmount);
        }
        if (response.users && !response.users.length) {
          setSpecialistData(undefined);
        }
        if (response.status && response.paramsAvailable) {
          setSpecialistData(response.users);
        }
        setIsLoader(false);
        if (response.users && !response.users.length) return;
        const [
          genders,
          cities,
          countriesAvailable,
          mainSpecs,
          subSpecs,
          languages,
          age,
          price,
        ] = await getAvailableParams(token, payload.filters);

        if (
          removeFilterElements(
            getExtraElements(selectedLangs as string[], languages),
            selectedLangs
          ).length !== selectedLangs.length
        ) {
          setSelectedLangs(
            removeFilterElements(
              getExtraElements(selectedLangs as string[], languages),
              selectedLangs
            ) as string[]
          );
        }
        if (
          removeFilterElements(
            getExtraElements(genderFilterValue as string[], genders),
            genderFilterValue as string[]
          ).length !== genderFilterValue.length
        ) {
          setGenderFilterValue(
            removeFilterElements(
              getExtraElements(genderFilterValue as string[], genders),
              genderFilterValue as string[]
            ) as string[]
          );
        }

        const responseLangList = [
          ...new Set(
            languages.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setLanguagesList(responseLangList);
        const responseGenderList = [
          ...new Set(
            genders.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setAvailableGenders(responseGenderList);

        const availableMainSpecialization = [
          ...new Set(
            mainSpecs.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setSpecializations((prev) =>
          prev
            ? { ...prev, mainSpecData: availableMainSpecialization }
            : { mainSpecData: availableMainSpecialization }
        );

        const availableSubSpecialization = [
          ...new Set(
            subSpecs.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];

        setSpecializations((prev) =>
          prev
            ? { ...prev, subSpecData: availableSubSpecialization }
            : {
                mainSpecData: availableSubSpecialization,
                subSpecData: availableMainSpecialization,
              }
        );

        setAgeRangeFilter((prev) => ({
          ...prev,
          value: {
            from: ageRangeFilter.value.from
              ? ageRangeFilter.value.from
              : //@ts-expect-error
                age.min,
            to: ageRangeFilter.value.from
              ? ageRangeFilter.value.to
              : //@ts-expect-error
                age.max,
          },
          //@ts-expect-error
          range: { min: age.min, max: age.max },
        }));

        setPriceRangeFilter((prev) => ({
          ...prev,
          value: {
            from: priceRangeFilter.value.from
              ? priceRangeFilter.value.from
              : //@ts-expect-error
                price.min,
            to: priceRangeFilter.value.from
              ? priceRangeFilter.value.to
              : //@ts-expect-error
                price.max,
          },
          //@ts-expect-error
          range: { min: price.min, max: price.max },
        }));
        const responseAvailableCountry = [
          ...new Set(
            countriesAvailable.filter((item) => !item.includes("undefined"))
          ),
        ];
        setAvailableCountry(
          responseAvailableCountry.map(
            (item) => countries.getAlpha2Code(item, "en") as string
          )
        );
        const responseAvailableCities = [
          ...new Set(
            cities
              .filter((item) => !item.includes("undefined"))
              .map((item, index) => ({
                id: index + 1,
                lable: item,
              }))
          ),
        ];

        setAvailableCity(responseAvailableCities);
        setFilterRanges({
          age: {
            //@ts-expect-error
            min: age.min,
            //@ts-expect-error
            max: age.max,
          },
          price: {
            //@ts-expect-error
            min: price.min,
            //@ts-expect-error
            max: price.max,
          },
        });
      }
    };
    makeAsync();
  }, [
    specializations.selectedMainSpec,
    specializations.selectedSubSpec,
    genderFilterValue,
    selectedLangs,
    filterCounter,
    page,
    rowsPerPage,
    selectedCity,
    selectedCountry,
    search,
    verifiedStatus,
  ]);

  const selectSpecialistHandler = (specialistId: string) => {
    if (!specialistData) return;
    const selectedSpec = specialistData.filter(
      (item) => item._id === specialistId
    )[0];
    setSelectedSpecialist(selectedSpec);
  };

  const changeVerifuStatusHandler = async (status: boolean) => {
    if (
      !token ||
      !userData ||
      !selectedSpecialist ||
      !selectedSpecialist.specialistId
    )
      return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist.specialistId._id, isVerified: status },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, verification: true }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistId) return prev;
        return {
          ...prev,
          specialistId: {
            ...prev.specialistId,
            isVerified: status,
          },
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistId &&
            specialist.specialistId._id === selectedSpecialist.specialistId!._id
          ) {
            return {
              ...specialist,
              specialistId: {
                ...specialist.specialistId,
                isVerified: status,
              },
            };
          }
          return specialist;
        });
      });
    }
  };

  const changeVerifyProfileStatus = async (status: boolean) => {
    if (
      !token ||
      !userData ||
      !selectedSpecialist ||
      !selectedSpecialist.specialistId
    )
      return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist.specialistId._id, isVerifiedChanges: status },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, changes: true }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistId) return prev;
        return {
          ...prev,
          specialistId: {
            ...prev.specialistId,
            isVerifiedChanges: status,
          },
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistId &&
            specialist.specialistId._id === selectedSpecialist.specialistId!._id
          ) {
            return {
              ...specialist,
              specialistId: {
                ...specialist.specialistId,
                isVerifiedChanges: status,
              },
            };
          }
          return specialist;
        });
      });
    }
  };

  useEffect(() => {
    if (!token || !selectedSpecialist) return;
    const makeAsync = async () => {
      const commentsResponse = await SessionsApi.getSpecialistsComments(
        token,
        selectedSpecialist._id,
        0,
        50
      );

      if (commentsResponse.status)
        setCommentsData(
          commentsResponse.comments.filter(
            (el) => el.customComment.length || el.defaultComments.length
          )
        );
    };
    makeAsync();
  }, [selectedSpecialist]);

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <div className={s.pageInfoTitleBlock}>
          <h1>Specialists</h1>
          <span>84% verified user profiles</span>
        </div>
        <button className={s.inviteSpecialistButtton}>+</button>
      </div>
      <div className={s.filterBlock}>
        <div className={s.searchInputBlock}>
          <input
            type="text"
            className={s.searchInput}
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={MagnifierIcon} alt="" className={s.magnifierIcon} />
        </div>
        <div className={s.filterButton} ref={filterRef}>
          <div
            className={isFilterOpen ? s.rectangle : s.circle}
            onClick={() => setIsFilterOpen(true)}
          >
            {isFilterOpen ? (
              <div className={s.inputsFilterBlock}>
                <div className={s.columnFilters}>
                  <div className={s.inputFilter}>
                    <ReactFlagsSelect
                      countries={availableCountry}
                      className={s.countrySelect}
                      selected={selectedCountry.code}
                      onSelect={(code) => {
                        setSelectedCountry({
                          code: code,
                          title: countries.getName(code, "en") as string,
                        });
                      }}
                      searchable
                      placeholder={
                        selectedCountry.code.length ? "" : "Select country"
                      }
                    />
                    <span
                      className={s.closeIconBlock}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCountry({ code: "", title: "" });
                      }}
                    >
                      <CloseIcon className={s.closeIcon} />
                    </span>
                  </div>
                  <div className={s.inputFilter}>
                    {availableCity && (
                      <MultipleSelect
                        data={availableCity.map((item) => ({
                          value: item.lable,
                          lable: item.lable,
                        }))}
                        setValue={(value) => {
                          setSelectedCity(value);
                        }}
                        value={selectedCity}
                        multiplie={true}
                        lable={"City"}
                        lableColor="red"
                        disabled={
                          selectedCountry &&
                          selectedCountry.code !== "" &&
                          availableCity.length
                            ? false
                            : true
                        }
                      />
                    )}
                  </div>
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={languagesList}
                      setValue={(value) => {
                        typeof value === "string"
                          ? setSelectedLangs([value])
                          : setSelectedLangs(value);
                      }}
                      value={selectedLangs}
                      multiplie={true}
                      lable={"Language"}
                      lableColor="red"
                    />
                  </div>
                  <div className={s.inputRangeFilter}>
                    <div className={s.rangeTitleBlock}>
                      <span>Age: </span>
                      <button
                        className={
                          /*  !checkRangeValue(
                            ageRangeFilter.value.from,
                            ageRangeFilter.value.to,
                            100,
                            18
                          )
                            ? s.btnDisabled
                            : */ s.rangeokButton
                        }
                        onClick={() =>
                          /*  checkRangeValue(
                            ageRangeFilter.value.from,
                            ageRangeFilter.value.to,
                            100,
                            18
                          ) && */ setFilterCounter((prev) => prev + 1)
                        }
                      >
                        OK
                      </button>
                    </div>
                    <div className={s.rangeNumberInputsBlock}>
                      <input
                        type="number"
                        value={
                          ageRangeFilter.value.from
                            ? ageRangeFilter.value.from
                            : ""
                        }
                        max={ageRangeFilter.range.max}
                        min={ageRangeFilter.range.min}
                        onChange={(e) =>
                          setAgeRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: Number(e.target.value),
                              to: ageRangeFilter.value.to,
                            },
                          }))
                        }
                      />
                      <input
                        type="number"
                        value={
                          ageRangeFilter.value.to ? ageRangeFilter.value.to : ""
                        }
                        max={ageRangeFilter.range.max}
                        min={ageRangeFilter.range.min}
                        onChange={(e) =>
                          setAgeRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: ageRangeFilter.value.from,
                              to: Number(e.target.value),
                            },
                          }))
                        }
                      />
                    </div>
                    <RangeSlider
                      minDistance={1}
                      setValue={setAgeRangeFilter}
                      value={[
                        ageRangeFilter.value.from,
                        ageRangeFilter.value.to,
                      ]}
                      min={filterRanges.age.min}
                      max={filterRanges.age.max}
                    />
                  </div>
                </div>
                <div className={s.columnFilters}>
                  {specializations && specializations.mainSpecData && (
                    <div className={s.inputFilter}>
                      <MultipleSelect
                        data={
                          specializations.mainSpecData
                            ? specializations.mainSpecData
                            : []
                        }
                        setValue={(value) => {
                          setSpecializations((prev) => ({
                            ...prev,
                            selectedMainSpec: value,
                            selectedSubSpec: [],
                          }));
                        }}
                        value={
                          specializations.selectedMainSpec
                            ? specializations.selectedMainSpec
                            : []
                        }
                        multiplie={true}
                        lable={"Main specialization"}
                        lableColor="red"
                      />{" "}
                    </div>
                  )}
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={
                        specializations.subSpecData
                          ? specializations.subSpecData
                          : []
                      }
                      setValue={(value) => {
                        setSpecializations((prev) => ({
                          ...prev,
                          selectedSubSpec: value,
                        }));
                      }}
                      value={
                        specializations.selectedSubSpec
                          ? specializations.selectedSubSpec
                          : []
                      }
                      multiplie={true}
                      lable={"Sub specialization"}
                      lableColor="red"
                      disabled={
                        specializations.selectedMainSpec &&
                        specializations.selectedMainSpec.length
                          ? false
                          : true
                      }
                    />
                  </div>
                  <div className={s.inputFilter}>
                    <MultipleSelect
                      data={
                        availableGenders ? availableGenders : genderSelectData
                      }
                      setValue={(value) => {
                        setGenderFilterValue(value);
                      }}
                      value={genderFilterValue}
                      multiplie={true}
                      lable={"Gender"}
                      lableColor="red"
                    />{" "}
                  </div>
                  <div className={s.inputRangeFilter}>
                    <div className={s.rangeTitleBlock}>
                      <span>Price: </span>
                      <button
                        className={
                          /* !checkRangeValue(
                            priceRangeFilter.value.from,
                            priceRangeFilter.value.to,
                            1000,
                            1
                          )
                            ? s.btnDisabled
                            : */ s.rangeokButton
                        }
                        onClick={() =>
                          /*  checkRangeValue(
                            priceRangeFilter.value.from,
                            priceRangeFilter.value.to,
                            1000,
                            1
                          ) && */ setFilterCounter((prev) => prev + 1)
                        }
                      >
                        OK
                      </button>
                    </div>
                    <div className={s.rangeNumberInputsBlock}>
                      <input
                        type="number"
                        value={
                          priceRangeFilter.value.from
                            ? priceRangeFilter.value.from
                            : ""
                        }
                        max={priceRangeFilter.range.max}
                        min={priceRangeFilter.range.min}
                        onChange={(e) =>
                          setPriceRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: Number(e.target.value),
                              to: priceRangeFilter.value.to,
                            },
                          }))
                        }
                      />
                      <input
                        type="number"
                        value={
                          priceRangeFilter.value.to
                            ? priceRangeFilter.value.to
                            : ""
                        }
                        max={priceRangeFilter.range.max}
                        min={priceRangeFilter.range.min}
                        onChange={(e) =>
                          setPriceRangeFilter((prev) => ({
                            ...prev,
                            value: {
                              from: priceRangeFilter.value.from,
                              to: Number(e.target.value),
                            },
                          }))
                        }
                      />
                    </div>
                    <RangeSlider
                      minDistance={1}
                      setValue={setPriceRangeFilter}
                      value={[
                        priceRangeFilter.value.from,
                        priceRangeFilter.value.to,
                      ]}
                      max={filterRanges.price.max}
                      min={filterRanges.price.min}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <FilterIcon className={s.filterIcon} />
            )}
          </div>
        </div>
        <div className={s.verifiedStatusFilterBlock}>
          <div
            className={s.selectedStatusBlock}
            onClick={(e) => {
              e.stopPropagation();
              setStatusFilterCollapsed((prev) => !prev);
            }}
            style={
              statusFilterCollapsed
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "3px",
                  }
            }
          >
            <span>
              {verifiedStatus === undefined
                ? "All"
                : verifiedStatus === false
                ? "Unverified"
                : "Verified"}
            </span>{" "}
            <ChevronDownIcon
              className={
                statusFilterCollapsed ? s.chevronDownIcon : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setStatusFilterCollapsed((prev) => !prev);
              }}
            />
          </div>
          <AnimateHeight
            height={statusFilterCollapsed ? 0 : "auto"}
            duration={500}
            className={s.animHeight}
          >
            {statusSelectData.map((item, index) => (
              <span
                key={index}
                onClick={() => {
                  setVerifiedStatus(item.value);
                  setStatusFilterCollapsed((prev) => !prev);
                }}
              >
                {item.lable}
              </span>
            ))}
          </AnimateHeight>
        </div>
      </div>
      <div className={s.contentBlock}>
        <div className={s.tableBlock}>
          {specialistData && specialistData.length ? (
            <SpecialistTable
              rows={specialistData.map((item, index) => ({
                id: item._id,
                name: item.name + " " + item.surname,
                email: item.email,
                specialization:
                  item.specialistId?.mainSpecializations[0].specialization,
                status: item.specialistId?.isVerified,
              }))}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
              selectSpecialistHandler={selectSpecialistHandler}
              count={totalAmount}
            />
          ) : (
            <div className={s.noSpecialistBlock}>
              <h1>No specialists found</h1>
            </div>
          )}
        </div>
        {specialistData && specialistData.length ? (
          <div
            className={`${s.specialistInfoBlock} ${
              selectedSpecialist && s.specialistInfoBlockActive
            }`}
          >
            <div
              className={s.backBlock}
              role="button"
              onClick={() => setSelectedSpecialist(undefined)}
            >
              <ChevronLeftIcon className={s.chevronBackIcon} />
              Back
            </div>
            <div className={s.headNameBlock}>
              {selectedSpecialist && (
                <CircleAvatar
                  /*  userId={selectedChat.members._id} */
                  name={selectedSpecialist?.name}
                  surname={selectedSpecialist?.surname}
                  avatarProps={avatars[selectedSpecialist._id]}
                />
              )}
              <span>
                {selectedSpecialist?.name + " " + selectedSpecialist?.surname}
              </span>
              {selectedSpecialist?.specialistId?.isVerified && <VerifyIcon />}
            </div>
            <div className={s.navigateSpecInfoBlock}>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "info" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("info")}
              >
                Info
              </div>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "complaints" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("complaints")}
              >
                Complaints
              </div>
              <div
                className={`${s.navElement} ${
                  selectNavigationElement === "settings" && s.selectNavElement
                }`}
                onClick={() => setSelectNavigationElement("settings")}
              >
                Settings
              </div>
              <div className={s.underLine}></div>
            </div>
            {selectNavigationElement === "info" ? (
              <div className={s.mainSpecInfoBlock}>
                <div className={s.personInfoTitleBlock}>
                  <h2 className={s.personInfoTitle}>Personal Information</h2>
                  <div
                    onClick={() => setIsProfileModalOpen((prev) => !prev)}
                    role="button"
                    className={s.viewAllBtn}
                  >
                    View all
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.infoElement}>
                    <span>Verified</span>
                    <span>
                      {selectedSpecialist?.specialistId?.isVerified
                        ? "Yes"
                        : "No"}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>First Name</span>
                    <span>{selectedSpecialist?.name}</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Last Name</span>
                    <span>{selectedSpecialist?.surname}</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Email</span>
                    <span>{selectedSpecialist?.email}</span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Specialization</span>
                    <span>
                      {
                        selectedSpecialist?.specialistId?.mainSpecializations[0]
                          .specialization
                      }
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>State</span>
                    <span>
                      {selectedSpecialist?.location?.includes("undefined")
                        ? "-"
                        : selectedSpecialist?.location}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Age</span>
                    <span>
                      {calculateAge(new Date(selectedSpecialist?.dateOfBirth!))}
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Practice hours</span>
                    <span>
                      {selectedSpecialist?.specialistId?.practiceHours}+
                    </span>
                  </div>
                  <div className={s.infoElement}>
                    <span>Years experience</span>
                    <span>
                      {selectedSpecialist?.specialistId?.yearsOfExperience}
                    </span>
                  </div>
                </div>
              </div>
            ) : selectNavigationElement === "complaints" ? (
              <div className={s.complaintsBlock}>
                {commentsData && commentsData.length ? (
                  [
                    ...commentsData,
                    ...commentsData,
                    ...commentsData,
                    ...commentsData,
                  ].map((item, index) => (
                    <CommentCard
                      id={item.commentBy}
                      commentMessage={item.customComment}
                      staticComments={item.defaultComments}
                      date={new Date(item.date).toISOString()}
                    />
                  ))
                ) : (
                  <h2>No Complaints</h2>
                )}
              </div>
            ) : (
              <div className={s.settingsBlock}>
                <div>
                  <div className={s.statusBlockTitle}>
                    <span></span>
                    <span>Verification status</span>
                  </div>
                  <div className={s.selectStatusBlock}>
                    <div
                      className={s.cuurentVerifyStatusBlock}
                      style={
                        selectStatusCollapsed.verification
                          ? {}
                          : {
                              borderBottom:
                                "1px solid rgba(255, 255, 255, 0.2)",
                              paddingBottom: "5px",
                            }
                      }
                      onClick={() =>
                        setSelectStatusCollapsed((prev) => ({
                          ...prev,
                          verification: !prev.verification,
                        }))
                      }
                    >
                      <span
                        className={
                          selectedSpecialist?.specialistId?.isVerified
                            ? s.verifiedColumn
                            : s.unverifiedColumn
                        }
                      >
                        {selectedSpecialist?.specialistId?.isVerified
                          ? "Verified"
                          : "Not verified"}
                      </span>
                      <ChevronDownIcon
                        className={
                          selectStatusCollapsed.verification
                            ? s.chevronDownIcon
                            : s.chevronUpIcon
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectStatusCollapsed((prev) => ({
                            ...prev,
                            verification: !prev.verification,
                          }));
                        }}
                      />
                    </div>
                    <AnimateHeight
                      height={selectStatusCollapsed.verification ? 0 : "auto"}
                      duration={500}
                      className={s.animHeightStatus}
                    >
                      <div
                        className={s.selectStatusElement}
                        role="button"
                        onClick={() => changeVerifuStatusHandler(true)}
                      >
                        <span className={s.verifiedColumn}>Verified</span>
                      </div>
                      <div
                        className={s.selectStatusElement}
                        role="button"
                        onClick={() => changeVerifuStatusHandler(false)}
                      >
                        <span className={s.unverifiedColumn}>Not verified</span>
                      </div>
                    </AnimateHeight>
                  </div>
                </div>
                <div className={s.profileChangeStatusBlock}>
                  <div className={s.profileVerifyStatusTitleBlock}>
                    <div className={s.statusBlockTitle}>
                      <span></span>
                      <span>Profile changes status</span>
                    </div>
                    <div
                      className={s.viewUpdatedProfileBtn}
                      role="button"
                      onClick={() => setIsUpdatedProfileModalOpen(true)}
                    >
                      View updated profile
                    </div>
                  </div>
                  <div className={s.selectStatusBlock}>
                    <div
                      className={s.cuurentVerifyStatusBlock}
                      style={
                        selectStatusCollapsed.changes
                          ? {}
                          : {
                              borderBottom:
                                "1px solid rgba(255, 255, 255, 0.2)",
                              paddingBottom: "5px",
                            }
                      }
                      onClick={() =>
                        setSelectStatusCollapsed((prev) => ({
                          ...prev,
                          changes: !prev.changes,
                        }))
                      }
                    >
                      <span
                        className={
                          selectedSpecialist?.specialistId?.isVerifiedChanges
                            ? s.verifiedColumn
                            : s.unverifiedColumn
                        }
                      >
                        {selectedSpecialist?.specialistId?.isVerifiedChanges
                          ? "Verified"
                          : "Not verified"}
                      </span>
                      <ChevronDownIcon
                        className={
                          selectStatusCollapsed.changes
                            ? s.chevronDownIcon
                            : s.chevronUpIcon
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectStatusCollapsed((prev) => ({
                            ...prev,
                            changes: !prev.changes,
                          }));
                        }}
                      />
                    </div>
                    <AnimateHeight
                      height={selectStatusCollapsed.changes ? 0 : "auto"}
                      duration={500}
                      className={s.animHeightStatus}
                    >
                      <div
                        className={s.selectStatusElement}
                        role="button"
                        onClick={() => changeVerifyProfileStatus(true)}
                      >
                        <span className={s.verifiedColumn}>Verified</span>
                      </div>
                      <div
                        className={s.selectStatusElement}
                        role="button"
                        onClick={() => changeVerifyProfileStatus(false)}
                      >
                        <span className={s.unverifiedColumn}>Not verified</span>
                      </div>
                    </AnimateHeight>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <ModalWindow
        isOpen={isProfileModalOpen}
        setIsOpen={setIsProfileModalOpen}
        height="90vh"
        width="90vw"
        padding="0 20px"
        /*  bgColor="rgb(46,46,46)" */
      >
        <div className={s.profileContainer}>
          {selectedSpecialist && (
            <Profile
              userId={selectedSpecialist._id}
              isOpen={isProfileModalOpen}
              setIsOpen={setIsProfileModalOpen}
              username={selectedSpecialist.username}
              isPublicProfile
            />
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={isUpdatedProfileModalOpen}
        setIsOpen={setIsUpdatedProfileModalOpen}
        height="90vh"
        width="90vw"
        padding="0 20px"
        /*  bgColor="rgb(46,46,46)" */
      >
        <div className={s.profileContainer}>
          {selectedSpecialist && (
            <Profile
              userId={selectedSpecialist._id}
              isOpen={isProfileModalOpen}
              setIsOpen={setIsProfileModalOpen}
              username={selectedSpecialist.username}
              isPublicProfile={false}
            />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default Specialists;
