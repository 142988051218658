import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./CreateProgram.module.css";
import { ContextProvider } from "../../../../../contextProvider";
import { Company, Project } from "../../../../../types/Company";
import Notify, { SuccesNotify } from "../../../../../utils/toaster";
import ProjectApi from "../../../../../api/projectApi";
import Input from "../../../../../components/Input/Input";
import MuiSwitch from "../../../../../components/MuiSwitch/MuiSwitch";
import StandartButton from "../../../../../components/StandartButton/StandartButton";

type CreateProgramProps = {
  selectedCompany: Company;
  setProgramModalVisible: Dispatch<SetStateAction<boolean>>;
  setProgramsData: Dispatch<SetStateAction<Project[] | null>>;
  isUpdateProgram?: boolean;
  programData?: Project;
};

const nextWeek = new Date(new Date().setDate(new Date().getDate() + 7));

const initialNewProgramData = {
  title: "",
  description: "",
  isPrivate: true,
  creationDate: new Date(),
  endDate: nextWeek,
  clientLimit: 1,
  specialistLimit: 1,
  sessionDiscount: 0,
};

const CreateProgram = ({
  selectedCompany,
  setProgramModalVisible,
  setProgramsData,
  isUpdateProgram,
  programData,
}: CreateProgramProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [newProgramData, setNewProgramData] = useState(initialNewProgramData);

  useEffect(() => {
    if (!isUpdateProgram || !programData) return;
    const currentProgramData = {
      title: programData.title,
      description: programData.description,
      isPrivate: programData.isPrivate,
      creationDate: new Date(programData.creationDate!),
      endDate: new Date(programData.endDate!),
      clientLimit: programData.clientLimit,
      specialistLimit: programData.specialistLimit,
      sessionDiscount: programData.sessionDiscount,
    };
    setNewProgramData(currentProgramData);
  }, [programData, isUpdateProgram]);

  const createProject = async () => {
    if (
      !newProgramData.clientLimit ||
      !newProgramData.clientLimit ||
      !newProgramData.title.length ||
      !newProgramData.description.length
    ) {
      return Notify("Fill in all the fields");
    }
    if (token && !isUpdateProgram) {
      const payload = {
        companyId: selectedCompany._id,
        newProject: newProgramData,
      };

      const response = await ProjectApi.createProject(token, payload);
      if (response.status && response.project) {
        setProgramsData((prev) =>
          prev ? [...prev, response.project] : [response.project]
        );
        SuccesNotify("Project has been created");
        setProgramModalVisible(false);
      }
    }
  };

  const formateDateForProgram = (value: Date) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const day = value.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const updateProject = async () => {
    if (!token || !programData) return;
    if (
      !newProgramData.clientLimit ||
      !newProgramData.clientLimit ||
      !newProgramData.title.length ||
      !newProgramData.description.length
    ) {
      return Notify("Fill in all the fields");
    }
    const updateData = { _id: programData._id, ...newProgramData };
    const response = await ProjectApi.update(token, updateData);

    if (response.status) {
      setProgramsData((prev) =>
        prev
          ? prev.map((item) => {
              if (item._id === programData._id) {
                return response.project;
              }
              return item;
            })
          : prev
      );
      SuccesNotify("Project has been updated");
      setProgramModalVisible(false);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.titleBlock}>
        <h2 className={s.headTitle}>
          {isUpdateProgram ? "Update Program" : "Create Program"}
        </h2>
        <Input
          inputValue={newProgramData.title}
          onChangeInput={(value) =>
            setNewProgramData((prev) => ({ ...prev, title: value }))
          }
          isVisible
          required
          lable="Program title"
        />
      </div>
      <div className={s.descriptionBlock}>
        <span className={s.inputTitle}>
          Enter the description of the program
        </span>
        <textarea
          name=""
          id=""
          cols={30}
          rows={7}
          value={newProgramData.description}
          className={s.textArea}
          onChange={(event) =>
            setNewProgramData((prev) => ({
              ...prev,
              description: event.target.value,
            }))
          }
        ></textarea>
      </div>
      <div className={s.inputsBlock}>
        <div className={s.privateBlock}>
          <MuiSwitch
            lable="Is private program?"
            lablePlacement="start"
            checked={newProgramData.isPrivate}
            setChecked={(value: boolean) =>
              setNewProgramData((prev) => ({ ...prev, isPrivate: value }))
            }
          />
        </div>
        <div className={s.endDateBlock}>
          <span className={s.inputTitle}>Program end date</span>
          <input
            type="date"
            value={formateDateForProgram(newProgramData.endDate!)}
            onChange={(e) =>
              setNewProgramData((prev) => ({
                ...prev,
                endDate: new Date(e.target.value),
              }))
            }
          />
        </div>
      </div>
      <div className={s.inputsBlock}>
        <Input
          inputValue={newProgramData.clientLimit.toString()}
          onChangeInput={(value) =>
            setNewProgramData((prev) => ({
              ...prev,
              clientLimit: Number(value),
            }))
          }
          isVisible
          required
          lable="Clients limit"
        />
        <Input
          inputValue={newProgramData.specialistLimit.toString()}
          onChangeInput={(value) =>
            setNewProgramData((prev) => ({
              ...prev,
              specialistLimit: Number(value),
            }))
          }
          isVisible
          required
          lable="Specialists limit"
        />
        <Input
          inputValue={newProgramData.sessionDiscount.toString()}
          onChangeInput={(value) =>
            setNewProgramData((prev) => ({
              ...prev,
              sessionDiscount: Number(value),
            }))
          }
          isVisible
          required
          lable="Session discount(%)"
        />
      </div>
      <div className={s.actionBlock}>
        <StandartButton
          action={isUpdateProgram ? updateProject : createProject}
          buttonTitle={isUpdateProgram ? "Update" : "Create"}
          width="200px"
        />
      </div>
    </div>
  );
};

export default CreateProgram;
