import React from "react";
import s from "./StatisticsCard.module.css";

type StatisticsCardProps = {
  title: string;
  value: number;
  unit?: string;
};

const StatisticsCard = ({ title, value, unit }: StatisticsCardProps) => {
  return (
    <div className={s.container}>
      <h4 className={s.cardTitle}>{title}</h4>
      <div>
        <span className={s.cardvalue}>{value}</span>
        <span className={s.unitTitle}> {unit}</span>
      </div>
    </div>
  );
};

export default StatisticsCard;
