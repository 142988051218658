import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./AddSpecialistsToCompany.module.css";
import { TypeUserData } from "../../../../types/TypeUsers";
import CircleAvatar from "../../../../components/CircleAvatar/CircleAvatar";
import Input from "../../../../components/Input/Input";
import { ContextProvider } from "../../../../contextProvider";
import UsersApi from "../../../../api/usersApi";
import { Company, Roles } from "../../../../types/Company";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { ReactComponent as CloseIcon } from "../../../../assets/General/close.svg";
import CompanyApi from "../../../../api/companyApi";
import { SuccesNotify } from "../../../../utils/toaster";
import Loader from "../../../../components/Loader/Loader";
import { ToastContainer } from "react-toastify";

type AddSpecialistsToCompanyProps = {
  selectedCompany: Company;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

const AddSpecialistsToCompany = ({
  selectedCompany,
  setModalVisible,
}: AddSpecialistsToCompanyProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [searchSpecValue, setSearchSpecValue] = useState("");
  const [specialistsData, setSpecialistsData] = useState<TypeUserData[]>([]);
  const [selectedSpecialists, setSelectedSpecialists] = useState<
    TypeUserData[]
  >([]);
  const [inviteLoader, setInviteLoader] = useState(false);

  useEffect(() => {
    if (!searchSpecValue.length) {
      setSpecialistsData([]);
    }
    const makeAsync = async () => {
      if (!token || !userData || searchSpecValue.length < 1) return;

      const response = await UsersApi.findUserBySearchString(
        token,
        searchSpecValue,
        "true"
      );

      if (response.status && response.users) {
        const specToCompanyIds = selectedCompany.specialists.map(
          (item) => item._id
        );

        const filteredSpecialists = response.users.filter(
          (spec) => !specToCompanyIds.includes(spec._id)
        );
        setSpecialistsData(filteredSpecialists);
      }
    };
    makeAsync();
  }, [token, searchSpecValue]);

  const inviteHandler = async () => {
    if (!token || !selectedCompany) return;
    const payload = {
      emails: selectedSpecialists.map((item) => item.email),
      companyId: selectedCompany._id,
      role: Roles.SPECIALIST as Roles.SPECIALIST,
    };
    setInviteLoader(true);
    const response = await CompanyApi.inviteUserToCompany(token, payload);
    setInviteLoader(false);

    if (response.status) {
      SuccesNotify("Invitation sent");
      setSelectedSpecialists([]);
      setModalVisible(false);
    }
  };

  const checkAlreadySelectSpec = (id: string) => {
    const specIdsArr = selectedSpecialists.map((selectSpec) => selectSpec._id);
    return specIdsArr.includes(id);
  };

  if (inviteLoader) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <h2 className={s.headTitle}>Invite specialists</h2>

      <span>Selected specialists</span>
      <div className={s.selectedSpecialistsBlock}>
        {selectedSpecialists.length ? (
          selectedSpecialists.map((item, index) => (
            <div className={s.selectedUserBlock} key={index}>
              <div className={s.selectedUser}>
                <CircleAvatar userId={item._id} width="40px" height="40px" />
                <span>{item.name + " " + item.surname}</span>
                <span>{item.email}</span>
              </div>
              <CloseIcon
                className={s.closeIcon}
                onClick={() =>
                  setSelectedSpecialists((prev) =>
                    prev.filter((el) => el._id !== item._id)
                  )
                }
              />
            </div>
          ))
        ) : (
          <span className={s.noSpecTitle}>No selected specialists</span>
        )}
      </div>

      <div className={s.selectSpecBlock}>
        <span>Select specialists pool</span>
        <span className={s.searchDescription}>
          (You can search users by name, username or email)
        </span>

        <Input
          inputValue={searchSpecValue}
          onChangeInput={(value) => setSearchSpecValue(value)}
          placeholder="Search..."
          isVisible
          required
          isSearchinput={searchSpecValue.length ? false : true}
          isCloseIcon={searchSpecValue.length ? true : false}
          closeFunction={() => setSearchSpecValue("")}
        />
        {specialistsData.length ? (
          <div className={s.searchResultBlock}>
            {specialistsData.map((item, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: checkAlreadySelectSpec(item._id)
                    ? "rgb(34, 34, 34)"
                    : "rgb(46, 46, 46)",
                }}
                className={
                  index === specialistsData.length - 1
                    ? s.lastUserBlock
                    : s.userBlock
                }
                onClick={() =>
                  checkAlreadySelectSpec(item._id)
                    ? setSelectedSpecialists((prev) =>
                        prev.filter((el) => el._id !== item._id)
                      )
                    : setSelectedSpecialists((prev) => [...prev, item])
                }
              >
                <CircleAvatar userId={item._id} width="40px" height="40px" />
                <span>{item.name + " " + item.surname}</span>
                <span>{item.email}</span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className={s.actionBlock}>
        <StandartButton
          action={inviteHandler}
          buttonTitle="Invite"
          width="200px"
          disabled={selectedSpecialists.length ? false : true}
        />
        <button className={s.cancelBtn} onClick={() => setModalVisible(false)}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddSpecialistsToCompany;
