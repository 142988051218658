import React, { useEffect, useState } from "react";
import UsersApi from "../../api/usersApi";
import s from "./CircleAvatar.module.css";
import ModalWindow from "../ModalWindow/ModalWindow";

type CircleAvatarProps = {
  userId?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  marginRight?: string;
  name?: string;
  surname?: string;
  avatarProps?: string;
  onClick?: (arg?: any) => void;
};

const CircleAvatar = ({
  userId,
  width,
  height,
  fontSize,
  name,
  surname,
  marginRight,
  avatarProps,
  onClick,
}: CircleAvatarProps) => {
  const token = localStorage.getItem("token");
  const [avatar, setAvatar] = useState<string | undefined>();
  const [isLoader, setIsLoader] = useState(true);
  const [nameSurname, setNameSurname] = useState({ name: "", surname: "" });
  const [modalAvatar, setModalAvatar] = useState(false);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userId) return;
      setIsLoader(true);
      const response = await UsersApi.getUserAvatarAndUsername(token, userId);
      setIsLoader(false);
      if (response.name && response.surname) {
        setNameSurname({ name: response.name, surname: response.surname });
      }
      if (response.avatar) {
        setAvatar(response.avatar);
      }
    };
    makeAsync();
  }, [userId]);

  return (
    <div
      className={s.container}
      onClick={
        onClick
          ? onClick
          : () => {
              return;
            }
      }
    >
      {avatar || avatarProps ? (
        <img
          src={avatarProps ? avatarProps : avatar}
          alt=""
          className={s.avatar}
          style={{
            width: width ? width : "50px",
            height: height ? height : "50px",
            marginRight: marginRight ? marginRight : "10px",
          }}
          onClick={() => setModalAvatar(true)}
        />
      ) : (
        <span
          className={s.emptyAvatarBlock}
          style={{
            width: width ? width : "50px",
            height: height ? height : "50px",
            fontSize: fontSize ? fontSize : "24px",
            marginRight: marginRight ? marginRight : "10px",
          }}
        >
          {nameSurname.name ? nameSurname.name[0] : name ? name[0] : ""}
          {nameSurname.surname
            ? nameSurname.surname[0]
            : surname
            ? surname[0]
            : ""}
        </span>
      )}
      <ModalWindow
        isOpen={modalAvatar}
        setIsOpen={setModalAvatar}
        maxWidth="fit-content"
        bgColor="transparent"
        width="fit-content"
      >
        <img
          src={avatarProps ? avatarProps : avatar}
          alt=""
          className={s.modalAvatar}
        />
      </ModalWindow>
    </div>
  );
};

export default CircleAvatar;
