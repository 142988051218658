import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./EmployerCard.module.css";
import {
  Project,
  Roles,
  TypeCompanyEmployee,
} from "../../../../../types/Company";
import { ContextProvider } from "../../../../../contextProvider";
import CompanyApi from "../../../../../api/companyApi";
import Notify, { SuccesNotify } from "../../../../../utils/toaster";
import ProjectApi from "../../../../../api/projectApi";
import CircleAvatar from "../../../../../components/CircleAvatar/CircleAvatar";
import { ReactComponent as SettingIcon } from "../../../../../assets/Companies/settings.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/Companies/delete.svg";
import { CircularProgress, ThemeProvider, Tooltip } from "@mui/material";
import ModalWindow from "../../../../../components/ModalWindow/ModalWindow";
import { theme } from "../../../../../utils/theme";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";

type EmployerCardProps = {
  companyId: string;
  userId: string;
  fullName: string;
  role: string;
  program: string[];
  email: string;
  avatar: string;
  setFilteredEmployers: Dispatch<SetStateAction<TypeCompanyEmployee[] | null>>;
};

const EmployerCard = ({
  companyId,
  fullName,
  role,
  program,
  avatar,
  email,
  userId,
  setFilteredEmployers,
}: EmployerCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [companyProjects, setCompanyProjects] = useState<Project[] | null>(
    null
  );
  const [removeLoader, setRemoveLoader] = useState(false);
  const [programSetupLoader, setProgramSetupLoader] = useState(false);
  const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false);
  const [
    removeUserFormProjectModalVisible,
    setRemoveUserFormProjectModalVisible,
  ] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null
  );
  const [programsSetupModalVisible, setProgramsSetupModalVisible] =
    useState(false);
  const [userProgramData, setUserProgramData] = useState(program);

  useEffect(() => {
    const makeAsync = async () => {
      if (token) {
        const response = await CompanyApi.getCompanyProjects(token, companyId);
        if (response.status) setCompanyProjects(response.projects);
      }
    };
    makeAsync();
  }, [companyId]);

  const removeUserfromCompany = async () => {
    if (token) {
      const payload = { userIdToRemove: userId, companyId: companyId };
      setRemoveLoader(true);
      const response = await CompanyApi.removeUserFromCompany(token, payload);
      setRemoveLoader(false);
      if (response.status) {
        setFilteredEmployers((prev) => {
          if (!prev) return prev;
          return prev.filter((emploee) => emploee.user._id !== userId);
        });
        SuccesNotify("User has been removed");
        setRemoveUserModalVisible(false);
      }
    }
  };

  const removeUserFromProject = async () => {
    if (token && selectedProjectId) {
      const payload = {
        userIdToRemove: userId,
        projectId: selectedProjectId,
      };
      setProgramSetupLoader(true);
      setRemoveUserFormProjectModalVisible(false);
      const response = await ProjectApi.removeUserFromProject(token, payload);
      setProgramsSetupModalVisible(false);
      setProgramSetupLoader(false);
      if (response.status) {
        const removedTitle =
          companyProjects &&
          companyProjects.filter(
            (project) => project._id === selectedProjectId
          );
        setUserProgramData((prev) =>
          prev.filter((program) => program !== removedTitle![0].title)
        );
        SuccesNotify("User has been removed");
      } else {
        Notify(response.message ? response.message : "Server Error");
      }
    }
  };
  const inviteUserToProjects = async (projectId: string) => {
    if (token) {
      setProgramSetupLoader(true);
      const response = await ProjectApi.inviteUserToProject(token, {
        emails: [email],
        projectId: projectId,
        role: Roles.DEEFAULT_USER,
      });

      if (response[0].status) {
        SuccesNotify("Invitation sent");
        setProgramsSetupModalVisible(false);
      }
      setProgramSetupLoader(false);
    }
  };

  return (
    <div className={s.cardContainer}>
      <div className={s.nameBlock}>
        <CircleAvatar userId={userId} />

        <div className={s.nameTitleBlock}>
          <span className={s.nameTitle}>{fullName}</span>
        </div>
      </div>

      <div className={s.positionBlock}>{role}</div>
      <div className={s.programBlock}>
        <div style={{ width: "70%" }}>
          {userProgramData.map((item, index) => (
            <span className={s.programTitleEl} key={index}>
              {item}
              {userProgramData.length > 1 &&
                index < userProgramData.length - 1 &&
                ","}
            </span>
          ))}
        </div>
        <SettingIcon
          className={s.settingIcon}
          onClick={() => setProgramsSetupModalVisible(true)}
        />
        <div className={s.mobileDeletBlock}>
          {role !== "owner" && (
            <Tooltip title={"Remove from company"} placement="right" arrow>
              <span
                className={s.deleteButtonBlock}
                onClick={() => setRemoveUserModalVisible(true)}
              >
                <DeleteIcon className={s.deletIcon} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={s.completedBlock}>
        <span className={s.emailTitle}>{email}</span>
        <div className={s.tripleDotIconBlock}>
          {role !== "owner" && (
            <Tooltip title={"Remove from company"} placement="right" arrow>
              <span
                className={s.deleteButtonBlock}
                onClick={() => setRemoveUserModalVisible(true)}
              >
                <DeleteIcon className={s.deletIcon} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <ModalWindow
        isOpen={programsSetupModalVisible}
        setIsOpen={setProgramsSetupModalVisible}
        width="fit-content"
        bgColor={programSetupLoader ? "transparent" : "white"}
      >
        {programSetupLoader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={140} color="primary" />
            </ThemeProvider>
          </div>
        ) : (
          <div className={s.programsModalContainer}>
            <h2>{"Programs"}</h2>
            <div className={s.programsList}>
              {companyProjects &&
                companyProjects.map((item, index) => (
                  <div className={s.programElement} key={index}>
                    <span>{item.title}</span>
                    {userProgramData.includes(item.title) ? (
                      <button
                        className={s.removeButton}
                        onClick={() => {
                          setSelectedProjectId(item._id);
                          setRemoveUserFormProjectModalVisible(true);
                        }}
                      >
                        Remove
                      </button>
                    ) : (
                      <StandartButton
                        action={() => inviteUserToProjects(item._id)}
                        buttonTitle={"Invite"}
                        height="40px"
                        fontSize="15px"
                        width="100px"
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </ModalWindow>
      <ConfirmationModal
        confirmFunc={removeUserfromCompany}
        confirmTitle={
          "Are you sure you want to remove an employee from the company?"
        }
        modalVisible={removeUserModalVisible}
        setModalVisible={setRemoveUserModalVisible}
        loader={removeLoader}
      />
      <ConfirmationModal
        confirmFunc={removeUserFromProject}
        confirmTitle={
          "Are you sure you want to remove an employee from the this program?"
        }
        modalVisible={removeUserFormProjectModalVisible}
        setModalVisible={setRemoveUserFormProjectModalVisible}
      />
    </div>
  );
};

export default EmployerCard;
