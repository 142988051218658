import { network } from "../config";
import ky from "ky";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SessionComment } from "../types/TypeSession";

const { sessions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "session/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SessionsApi {
  static async getSpecialistsComments(
    token: string,
    userId: string,
    limit: number,
    page: number
  ): Promise<
    TypeDefaultResponse & {
      comments: (
        | SessionComment & {
            _id: string;
          }
      )[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${sessions.getSpecialistsComments}/${userId}/${limit}/${page}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
}
