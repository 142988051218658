import { TypeUserData } from "./TypeUsers";

export enum SpecialistLevels {
  STUDENT = "STUDENT",
  GRADUATED_STUDENT = "GRADUATED STUDENT",
  LEVEL1 = "LEVEL1",
  LEVEL2 = "LEVEL2",
}

export enum PaymentForType {
  REAL_TIME_SESSION = "REAL_TIME_SESSION",
  SESSION = "SESSION",
  SESSION_PACKAGE = "SESSION_PACKAGE",
}

export type SubscriptionFeatures = {
  title: string;
  description?: string;
  icon?: string;
  titleLabels: { text: string; language: string }[];
  descriptionLabels: { text: string; language: string }[];
};

export type SubscriptionInfo = {
  _id: string;
  title: string;
  description?: string;
  icon?: string;
  features?: SubscriptionFeatures[];
  titleLabels: { text: string; language: string }[];
  descriptionLabels: { text: string; language: string }[];
};

export type Subscription = {
  _id: string;
  title: string;
  description?: string;
  costPerMonth: number;
  discount: { for: PaymentForType | SpecialistLevels; amount: number }[];
  accessibleLevels: SpecialistLevels[];
  discountsForSubscription: { days: number; amount: number }[];
  shortInfoList: SubscriptionInfo[];
  isOn: boolean;
  titleLabels: { text: string; language: string }[];
  descriptionLabels: { text: string; language: string }[];
};
export type UserSubscription = {
  _id: string;
  user: TypeUserData;
  subscription: Subscription;
  startDate: string;
  endDate: string;
  isActive: boolean;
};
