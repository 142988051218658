import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import s from "./UpdateCompany.module.css";
import { ReactComponent as CloseIcon } from "../../../../assets/General/close.svg";
import { ContextProvider } from "../../../../contextProvider";
import Input from "../../../../components/Input/Input";
import CircleAvatar from "../../../../components/CircleAvatar/CircleAvatar";
import { TypeUserData } from "../../../../types/TypeUsers";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { Company } from "../../../../types/Company";
import UsersApi from "../../../../api/usersApi";
import CompanyApi from "../../../../api/companyApi";

type UpdateCompanyProps = {
  selectedCompany: Company;
  setSelectedCompany: Dispatch<SetStateAction<Company | null>>;
  setUpdateModalVisible: Dispatch<SetStateAction<boolean>>;
  setCompaniesData: Dispatch<SetStateAction<Company[] | null>>;
};

const UpdateCompany = ({
  selectedCompany,
  setSelectedCompany,
  setUpdateModalVisible,
  setCompaniesData,
}: UpdateCompanyProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [newCompany, setNewCompany] = useState({
    title: selectedCompany.name,
    owner: {
      _id: selectedCompany.owner._id,
      name: selectedCompany.owner.name,
      surname: selectedCompany.owner.surname,
      email: "",
    },
  });
  const [searchUsers, setSearchUsers] = useState("");
  const [usersData, setUsersData] = useState<TypeUserData[]>([]);

  useEffect(() => {
    if (newCompany.owner._id.length) {
      setSearchUsers("");
    }
  }, [newCompany]);

  useEffect(() => {
    if (!searchUsers.length) {
      setUsersData([]);
    }
    const makeAsync = async () => {
      if (!token || !userData || searchUsers.length < 1) return;
      const response = await UsersApi.findUserBySearchString(
        token,
        searchUsers,
        "false"
      );
      if (response.status && response.users) {
        setUsersData(response.users);
      }
    };
    makeAsync();
  }, [token, searchUsers]);

  const updateCompany = async () => {
    if (!token) return;
    const paylaod = {
      _id: selectedCompany._id,
      owner: newCompany.owner._id,
      name: newCompany.title,
    };
    const resposne = await CompanyApi.updateCompany(token, paylaod);
    if (resposne.status && resposne.company) {
      setSelectedCompany((prev) =>
        prev
          ? {
              ...prev,
              owner: resposne.company.owner,
              name: resposne.company.name,
            }
          : resposne.company
      );
      setCompaniesData((prev) =>
        prev
          ? prev.map((company) =>
              company._id === resposne.company._id
                ? {
                    ...company,
                    owner: {
                      name: newCompany.owner.name,
                      surname: newCompany.owner.surname,
                      _id: newCompany.owner._id,
                    } as TypeUserData,
                    name: resposne.company.name,
                  }
                : company
            )
          : prev
      );
      setUpdateModalVisible(false);
    }
  };
  return (
    <div className={s.container}>
      <h2 className={s.newCompanyTitle}>Update company</h2>
      <Input
        inputValue={newCompany.title}
        onChangeInput={(value) =>
          setNewCompany((prev) => ({ ...prev, title: value }))
        }
        isVisible
        required
        placeholder="Company..."
        lable="Company name"
      />
      <div className={s.selectOwnerBlock}>
        <span>Select owner for company</span>
        <span className={s.searchDescription}>
          (You can search users by name, username or email)
        </span>
        {newCompany.owner._id.length ? (
          <div className={s.selectedUserBlock}>
            <div className={s.selectedUser}>
              <CircleAvatar
                userId={newCompany.owner._id}
                width="40px"
                height="40px"
              />
              <span>
                {newCompany.owner.name + " " + newCompany.owner.surname}
              </span>
              <span>{newCompany.owner.email}</span>
            </div>
            <CloseIcon
              className={s.closeIcon}
              onClick={() =>
                setNewCompany((prev) => ({
                  ...prev,
                  owner: { _id: "", name: "", surname: "", email: "" },
                }))
              }
            />
          </div>
        ) : (
          <Input
            inputValue={searchUsers}
            onChangeInput={(value) => setSearchUsers(value)}
            placeholder="Search..."
            isVisible
            required
            isSearchinput
          />
        )}
      </div>
      <div className={s.createBtnBlock}>
        <StandartButton
          action={updateCompany}
          buttonTitle="Update"
          width="200px"
          disabled={
            !newCompany.owner._id.length || !newCompany.title.length
              ? true
              : false
          }
          tooltipTitle="Fill in all the information about the company"
        />
        <button
          className={s.cancelBtn}
          onClick={() => setUpdateModalVisible(false)}
        >
          Cancel
        </button>
        {usersData.length ? (
          <div className={s.searchResultBlock}>
            {usersData.map((item, index) => (
              <div
                key={index}
                className={
                  index === usersData.length - 1 ? s.lastUserBlock : s.userBlock
                }
                onClick={() =>
                  setNewCompany((prev) => ({
                    ...prev,
                    owner: {
                      _id: item._id,
                      name: item.name!,
                      surname: item.surname!,
                      email: item.email!,
                    },
                  }))
                }
              >
                <CircleAvatar userId={item._id} width="40px" height="40px" />
                <span>{item.name + " " + item.surname}</span>
                <span>{item.email}</span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UpdateCompany;
