import { callApi } from "./apiUtils";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  GetUserAvatar,
  TypeConfirmRegister,
  TypeNewUser,
  TypeUpdatedUserInfo,
  TypeUserData,
} from "../types/TypeUsers";
import { SpecialistData } from "../types/TypeSpecialist";
import { network } from "../config";
const { users } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "users/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class UsersApi {
  static async getUserById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & { userData?: TypeUserData | null; token?: string }
  > {
    return callApi(`${baseURL}${users.getUserById}/${id}`, {
      headers: getAuthHeader(token),
    });
  }

  static async getUserProfileInfoById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistId?: SpecialistData }) | null;
    }
  > {
    return callApi(`${baseURL}${users.getUserProfileInfoById}/${id}`, {
      headers: getAuthHeader(token),
    });
  }
  static async getUserPublicInfo(
    username: string,
    selectedLanguage: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistId?: SpecialistData }) | null;
    }
  > {
    return callApi(
      `${baseURL}${users.getUserPublicInfo}/${username}/${selectedLanguage}`
    );
  }

  static async getUserByToken(token: string): Promise<TypeUserData | null> {
    return callApi(`${baseURL}${users.getUserByToken}/${token}`, {
      headers: getAuthHeader(token),
    });
  }

  static async auth(
    usernameOrEmail: string,
    password: string
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
    }
  > {
    return callApi(`${baseURL}${users.auth}`, {
      method: "POST",
      json: { usernameOrEmail: usernameOrEmail, password: password },
    });
  }

  static async confirmRegister(confirmedData: TypeConfirmRegister): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
    }
  > {
    return callApi(`${baseURL}${users.confirmRegister}`, {
      method: "POST",
      json: confirmedData,
    });
  }

  static async confirmPassUpdate(
    email: string
  ): Promise<TypeDefaultResponse & { id: string }> {
    return callApi(`${baseURL}${users.confirmPassUpdate}`, {
      method: "POST",
      json: { email },
    });
  }

  static async updatePassword(updateData: {
    password: string;
    id: string;
    confirmationCode: number;
  }): Promise<
    TypeDefaultResponse & {
      password: string;
      id: string;
      confirmationCode: number;
    }
  > {
    return callApi(`${baseURL}${users.updatePassword}`, {
      method: "POST",
      json: updateData,
    });
  }

  static async updateUserData(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
    }
  > {
    return callApi(`${baseURL}${users.update}/${updateData._id}`, {
      headers: getAuthHeader(token),
      method: "POST",
      json: { updateData },
    });
  }

  static async getUserAvatarAndUsername(
    token: string,
    id: string
  ): Promise<GetUserAvatar> {
    return callApi(`${baseURL}${users.getUserAvatarAndUsername}/${id}`, {
      headers: getAuthHeader(token),
    });
  }

  static async checkIfUserJoinedAnyCompany(
    token: string,
    userId: string
  ): Promise<GetUserAvatar> {
    return callApi(`${baseURL}${users.checkIfUserJoinedAnyCompany}/${userId}`, {
      headers: getAuthHeader(token),
    });
  }

  static async fixUserAvatars(token: string): Promise<GetUserAvatar> {
    return callApi(`${baseURL}${users.fixUserAvatars}`, {
      method: "POST",
      headers: getAuthHeader(token),
    });
  }

  static async findUserBySearchString(
    token: string,
    searchString: string,
    searchForSpecialistOnly: "true" | "false"
  ): Promise<TypeDefaultResponse & { users?: TypeUserData[] }> {
    return callApi(
      `${baseURL}${users.findUserBySearchString}/${searchString}/${searchForSpecialistOnly}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }

  static async getUserSelectedFields(
    token: string,
    userId: string,
    selectUserFields: string,
    selectSpecFields: string
  ): Promise<TypeDefaultResponse & { userData?: Partial<TypeUserData> }> {
    return callApi(
      `${baseURL}${users.getUserSelectedFields}/${userId}/${selectUserFields}/${selectSpecFields}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }
}
