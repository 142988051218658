import React, { useContext, useEffect, useState } from "react";
import s from "./CompanyPrograms.module.css";
import { ContextProvider } from "../../../../contextProvider";
import { Company, Project } from "../../../../types/Company";
import CompanyApi from "../../../../api/companyApi";
import ProgramCard from "./ProgramCard/ProgramCard";
import Loader from "../../../../components/Loader/Loader";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import CreateProgram from "./CreateProgram/CreateProgram";
import { ToastContainer } from "react-toastify";
import ProgramInfo from "./ProgramInfo/ProgramInfo";

type CompanyPrograms = {
  selectedCompany: Company;
};

const CompanyPrograms = ({ selectedCompany }: CompanyPrograms) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [programsData, setProgramsData] = useState<Project[] | null>(null);
  const [actionLoaders, setActionLoaders] = useState({ loadProjects: false });
  const [newProgramModal, setNewProgramModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<Project | null>(null);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      setActionLoaders((prev) => ({ ...prev, loadProjects: true }));
      const response = await CompanyApi.getCompanyProjects(
        token,
        selectedCompany._id
      );

      setActionLoaders((prev) => ({ ...prev, loadProjects: false }));
      if (response.status && response.projects) {
        setProgramsData(response.projects);
      }
    };
    makeAsync();
  }, [selectedCompany]);

  return (
    <div className={s.container}>
      <div
        className={`${s.allProgramsBlock} ${
          selectedProgram ? s.allProgramsBlockHidden : s.allProgramsBlockVisible
        }`}
      >
        <div className={s.actionBlock}>
          <StandartButton
            action={() => setNewProgramModal(true)}
            buttonTitle="Create program"
          />
        </div>
        {programsData && programsData.length ? (
          <div className={s.lableTableBlock}>
            <span>Program title</span>
            <span>Clients</span>
            <span>Specialists</span>
            <span>Discount</span>
            <span>Is private</span>
            <span>End date</span>
          </div>
        ) : null}
        <div className={s.tableBlock}>
          {programsData && programsData.length ? (
            programsData.map((item, index) => (
              <ProgramCard
                index={index}
                programData={item}
                dataLength={programsData.length}
                setProgramsData={setProgramsData}
                selectedCompany={selectedCompany}
                setSelectedProgram={setSelectedProgram}
                key={index}
              />
            ))
          ) : (
            <div className={s.noProgramsBlock}>
              {actionLoaders.loadProjects ? (
                <Loader size={100} />
              ) : (
                <h2>No programs</h2>
              )}
            </div>
          )}
          <ToastContainer />
        </div>
      </div>
      <div
        className={`${s.programInfoBlock} ${
          selectedProgram ? s.programInfoBlockVisible : s.programInfoBlockHidden
        }`}
      >
        {selectedProgram && (
          <ProgramInfo
            selectedProgram={selectedProgram}
            setSelectedProgram={setSelectedProgram}
            companyData={selectedCompany}
          />
        )}
      </div>
      <ModalWindow
        isOpen={newProgramModal}
        setIsOpen={setNewProgramModal}
        maxWidth="fit-content"
      >
        <CreateProgram
          selectedCompany={selectedCompany}
          setProgramModalVisible={setNewProgramModal}
          setProgramsData={setProgramsData}
        />
      </ModalWindow>
      <ToastContainer />
    </div>
  );
};

export default CompanyPrograms;
