import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from "react";
import s from "./SubscriptionInfoCard.module.css";
import { InfoCard, TranslatableText } from "../NewSubscription";
import Input from "../../../../components/Input/Input";
import useAutosizeTextArea from "../../../../hooks/useAutosizeTextarea";
import { ReactComponent as UploadIcon } from "../../../../assets/General/uplaod.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/General/close.svg";
import { ReactComponent as RepeatIcon } from "../../../../assets/General/repeat.svg";

type SubscriptionInfoCardProps = {
  icon?: File;
  title: TranslatableText[];
  description: TranslatableText[];
  isFeatures: boolean;
  index: number;
  setInfoCard: Dispatch<SetStateAction<InfoCard[]>>;
  selectedLanguages: string;
  featureIndex?: number;
};

const SubscriptionInfoCard = ({
  icon,
  title,
  description,
  isFeatures,
  index,
  setInfoCard,
  selectedLanguages,
  featureIndex,
}: SubscriptionInfoCardProps) => {
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(
    descriptionRef.current,
    description.find((el) => el.language === selectedLanguages)?.text!
  );
  const getImageSrc = (image: File | string) => {
    if (!image) return;
    return typeof image === "string" ? image : URL.createObjectURL(image);
  };

  const handleIconChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setInfoCard((prevState: InfoCard[]) => {
      return prevState.map((item, itemIndex) => {
        if (itemIndex === index) {
          return isFeatures && featureIndex !== undefined
            ? {
                ...item,
                features: item.features.map((feature, featureIndexInner) =>
                  featureIndexInner === featureIndex
                    ? { ...feature, icon: file }
                    : feature
                ),
              }
            : { ...item, icon: file };
        }
        return item;
      });
    });
  };

  const handleTitleChange = (value: string) => {
    setInfoCard((prevState: InfoCard[]) => {
      const newState = prevState.map((item, itemIndex) => {
        if (itemIndex === index) {
          const itemToUpdate =
            isFeatures && featureIndex !== undefined
              ? {
                  ...item,
                  features: item.features.map((feature, featureIndexInner) =>
                    featureIndexInner === featureIndex
                      ? {
                          ...feature,
                          title: feature.title.map((t) =>
                            t.language === selectedLanguages
                              ? { ...t, text: value }
                              : t
                          ),
                        }
                      : feature
                  ),
                }
              : {
                  ...item,
                  title: item.title.map((t) =>
                    t.language === selectedLanguages ? { ...t, text: value } : t
                  ),
                };
          return itemToUpdate;
        }
        return item;
      });
      return newState;
    });
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;

    setInfoCard((prevState: InfoCard[]) => {
      const newState = prevState.map((item, itemIndex) => {
        if (itemIndex === index) {
          const itemToUpdate =
            isFeatures && featureIndex !== undefined
              ? {
                  ...item,
                  features: item.features.map((feature, featureIndexInner) =>
                    featureIndexInner === featureIndex
                      ? {
                          ...feature,
                          description: feature.description.map((d) =>
                            d.language === selectedLanguages
                              ? { ...d, text: newText }
                              : d
                          ),
                        }
                      : feature
                  ),
                }
              : {
                  ...item,
                  description: item.description.map((d) =>
                    d.language === selectedLanguages
                      ? { ...d, text: newText }
                      : d
                  ),
                };
          return itemToUpdate;
        }
        return item;
      });
      return newState;
    });
  };
  const deleteIconHandler = () => {
    setInfoCard((prevState: InfoCard[]) => {
      return prevState.map((item, itemIndex) => {
        if (itemIndex === index) {
          return isFeatures && featureIndex !== undefined
            ? {
                ...item,
                features: item.features.map((feature, featureIndexInner) =>
                  featureIndexInner === featureIndex
                    ? { ...feature, icon: undefined }
                    : feature
                ),
              }
            : { ...item, icon: undefined };
        }
        return item;
      });
    });
  };
  return (
    <div className={s.container}>
      <div className={s.iconBlock}>
        {icon ? (
          <div className={s.iconBlock}>
            <div className={s.iconActionBlock}>
              <label className={s.avatarInputBlock}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept=".svg"
                  onChange={(e) => handleIconChange(e)}
                />

                <RepeatIcon className={s.iconActionBtn} />
              </label>
              <CloseIcon
                className={s.iconActionBtn}
                onClick={deleteIconHandler}
              />
            </div>
            <img src={getImageSrc(icon)} className={s.icon} />
          </div>
        ) : (
          <label className={s.avatarInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".svg"
              onChange={(e) => handleIconChange(e)}
            />
            <div className={s.uploadIconBlock}>
              <UploadIcon className={s.uplaodIcon} />
              <span>Icon</span>
            </div>
          </label>
        )}
      </div>
      <div className={s.inputsBlock}>
        <Input
          inputValue={
            title.find((el) => el.language === selectedLanguages)?.text ?? ""
          }
          onChangeInput={(value) => handleTitleChange(value)}
          isVisible
          required
          maxLength={70}
          placeholder="Title..."
        />
        <textarea
          rows={1}
          value={
            description.find((el) => el.language === selectedLanguages)?.text ??
            ""
          }
          className={s.textArea}
          placeholder="Description..."
          ref={descriptionRef}
          onChange={handleDescriptionChange}
          maxLength={200}
        />
      </div>
    </div>
  );
};

export default SubscriptionInfoCard;
