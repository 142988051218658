import React, { Dispatch, ReactNode, SetStateAction } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import s from "./ModalWindow.module.css";

type ModalWindowProps = {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  width?: string;
  height?: string;
  bgColor?: string;
  padding?: string;
  position?: "right" | "left";
  maxWidth?: string;
};
const ModalWindow = ({
  children,
  isOpen,
  setIsOpen,
  width,
  height,
  bgColor,
  padding,
  position,
  maxWidth,
}: ModalWindowProps) => {
  return (
    <div style={{ outline: "none" }}>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={s.modal}
      >
        <div
          className={
            position === "right"
              ? s.modalContentRight
              : position === "left"
              ? s.modalContentLeft
              : s.modalContent
          }
          style={{
            maxWidth: maxWidth ?? "auto",
            width: width ? width : "50%",
            height: height ? height : "fit-content",
            backgroundColor: bgColor ? bgColor : "black",
            padding: padding
              ? padding
              : window.innerWidth > 768
              ? "20px"
              : "20px",
            /* outline: "none", */
          }}
        >
          {children}
        </div>
      </Modal>
    </div>
  );
};
export default ModalWindow;
