type StringOrObjectArray = string[] | { value: string; lable: string }[];

export const getExtraElements = (
  superSet: string[],
  subSet: StringOrObjectArray
): string[] => {
  let subSetValues: Set<string>;
  if (subSet.length > 0 && typeof subSet[0] === "object") {
    subSetValues = new Set(
      (subSet as { value: string; lable: string }[]).map((item) => item.value)
    );
  } else {
    subSetValues = new Set(subSet as string[]);
  }

  return superSet.filter((element) => !subSetValues.has(element));
};

export const removeFilterElements = (
  elementsToRemove: string[],
  list: StringOrObjectArray
): StringOrObjectArray => {
  const removalSet = new Set(elementsToRemove);

  if (list.length === 0 || typeof list[0] === "string") {
    return (list as string[]).filter((element) => !removalSet.has(element));
  }

  return (list as { value: string; lable: string }[]).filter(
    (item) => !removalSet.has(item.value)
  );
};
