import React, { useEffect, useState } from "react";
import s from "./Subscriptions.module.css";
import SubscriptionsTable from "../../components/SubscriptionsTable/SubscriptionsTable";
import {
  PaymentForType,
  SpecialistLevels,
  Subscription,
} from "../../types/Subscription";
import StandartButton from "../../components/StandartButton/StandartButton";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import NewSubscription from "./NewSubscription/NewSubscription";
import { ToastContainer } from "react-toastify";
import SubscriptionsAPI from "../../api/subscriptionsApi";

const Subscriptions = () => {
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isNewSubscripWindowOpen, setIsNewSubscripWindowOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);
  const [subscriptionsData, setSubscriptionsData] = useState<
    Subscription[] | null
  >(null);
  const subscripData = [
    {
      _id: "111111111111",
      title: "Plan 1",
      costPerMonth: 100,
      discountsForSubscription: [
        { days: 30, amount: 0 },
        { days: 90, amount: 10 },
        { days: 180, amount: 20 },
      ],
      totalSubscribers: 90,
      status: true,
    },
    {
      _id: "2",
      title: "Plan 2",
      costPerMonth: 120,
      discountsForSubscription: [
        { days: 30, amount: 0 },
        { days: 90, amount: 30 },
        { days: 180, amount: 40 },
      ],
      totalSubscribers: 978,
      status: false,
    },
  ];

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const paylaod = {
        page: page,
        limit: rowsPerPage,
      };
      const response = await SubscriptionsAPI.getAllSubscriptions(
        token,
        paylaod
      );

      if (response.status && response.subscriptions) {
        setSubscriptionsData(response.subscriptions);
        setTotalAmount(response.subscriptionsAmount);
      }
    };
    makeAsync();
  }, []);

  const selectSubscriptionHandler = (id: string) => {
    console.log(id);
  };
  return (
    <div className={s.container}>
      <div
        className={`${s.allSubscriptionsContainer} ${
          selectedSubscription
            ? s.allSubscriptionsHidden
            : s.allSubscriptionsVisible
        }`}
      >
        <div className={s.headerBlock}>
          <h2>Subscriptions</h2>
          <StandartButton
            action={() => setIsNewSubscripWindowOpen(true)}
            buttonTitle="New subscription"
          />
        </div>
        {subscriptionsData && subscriptionsData.length ? (
          <div className={s.tableBlock}>
            <SubscriptionsTable
              count={totalAmount}
              page={page}
              rows={subscriptionsData}
              rowsPerPage={rowsPerPage}
              selectSubscripHandler={selectSubscriptionHandler}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </div>
        ) : (
          <div className={s.noSubBlock}>
            <h2>No subscriptions</h2>
          </div>
        )}
      </div>
      <div
        className={`${s.selectedSubscriptionInfo} ${
          selectedSubscription
            ? s.subscriptionInfoVisible
            : s.subscriptionHidden
        }`}
      >
        <h1>Subscriptions Info</h1>
      </div>
      <ModalWindow
        isOpen={isNewSubscripWindowOpen}
        setIsOpen={setIsNewSubscripWindowOpen}
        maxWidth="fit-content"
        width="fit-content"
      >
        <NewSubscription setModalVisible={setIsNewSubscripWindowOpen} />
      </ModalWindow>
      <ToastContainer />
    </div>
  );
};

export default Subscriptions;
