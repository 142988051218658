import React, { Dispatch, SetStateAction } from "react";
import s from "./ConfirmationModal.module.css";
import ModalWindow from "../ModalWindow/ModalWindow";
import StandartButton from "../StandartButton/StandartButton";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
import { ReactComponent as WarningIcon } from "../../assets/General/warning.svg";

type ConfirmationModalProps = {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  confirmTitle: string;
  confirmFunc: (arg?: any) => void;
  loader?: boolean;
};

const ConfirmationModal = ({
  modalVisible,
  setModalVisible,
  confirmTitle,
  confirmFunc,
  loader,
}: ConfirmationModalProps) => {
  return (
    <ModalWindow
      isOpen={modalVisible}
      setIsOpen={setModalVisible}
      width={window.innerWidth > 768 ? "500px" : "90svw"}
      bgColor={loader ? "transparent" : "rgb(46, 46, 46)"}
    >
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ThemeProvider theme={theme}>
            <CircularProgress size={140} color="primary" />
          </ThemeProvider>
        </div>
      ) : (
        <div className={s.container}>
          <div className={s.warningBlock}>
            <WarningIcon className={s.warningIcon} />
          </div>
          <h3 className={s.confirmTitle}>{confirmTitle}</h3>
          <div className={s.actionBlock}>
            <StandartButton
              action={confirmFunc}
              buttonTitle={"Confirm"}
              height="40px"
              width="120px"
              fontSize="18px"
            />
            <button
              className={s.cancelButton}
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

export default ConfirmationModal;
