export const generateNumbersArray = (start: number, end: number) => {
  var arrayOfStrings = [];
  for (var i = start; i <= end; i++) {
    arrayOfStrings.push(String(i));
  }
  return arrayOfStrings;
};
export const daysList = generateNumbersArray(1, 31).map((item) => ({
  value: item,
  lable: item,
  color: "black",
}));

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthList = generateNumbersArray(1, 12).map((item, index) => {
  return {
    value: item,
    lable: monthNames[index],
  };
});
export const yearsList = generateNumbersArray(1950, 2024).map((item) => ({
  value: item,
  lable: item,
}));

export const calculateAge = (birthDate: Date): number => {
  const currentDate: Date = new Date();
  let age: number = currentDate.getFullYear() - birthDate.getFullYear();
  const currentMonth: number = currentDate.getMonth();
  const birthMonth: number = birthDate.getMonth();

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const formatDate = (date: Date): string => {
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1;
  const year: number = date.getFullYear();

  const formattedDay: string = day < 10 ? "0" + day : "" + day;
  const formattedMonth: string = month < 10 ? "0" + month : "" + month;

  return formattedDay + "." + formattedMonth + "." + year;
};
