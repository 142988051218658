import React, { useEffect, useState } from "react";
import "./App.css";
import Main from "./layouts/Main/Main";
import "./variables.scss";
import { ContextProvider, TypeContextProvider } from "./contextProvider";
import Auth from "./layouts/Auth/Auth";
import { TypeUserData } from "./types/TypeUsers";
import UsersApi from "./api/usersApi";
import { logout } from "./utils/general";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "./utils/theme";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const token = localStorage.getItem("token");
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState<TypeUserData | null | undefined>(
    null
  );
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [token]);
  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return setIsLoader(false);
      setIsAuth(true);
      try {
        const responseUserData = await UsersApi.getUserByToken(token);
        setIsLoader(false);
        if (responseUserData && responseUserData.roles) {
          setIsAuth(true);
          setUserData(responseUserData);
        } else {
          logout();
          setIsAuth(false);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          logout();
          setIsAuth(false);
          setIsLoader(false);
        } else {
          console.error("Error during API request:", error);
          logout();
          setIsAuth(false);
          setIsLoader(false);
        }
      }
    };

    makeAsync();
  }, [token]);

  const contextValue: TypeContextProvider = {
    isAuth,
    setIsAuth,
    userData,
  };
  if (isLoader) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <ContextProvider.Provider value={contextValue}>
      {isAuth ? <Main /> : <Auth />}
    </ContextProvider.Provider>
  );
}

export default App;
